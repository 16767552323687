<div class="row d-flex justify-content-center">
    <div class="card-body col-12 col-lg-12 pt-0 pb-0 row bg-white busca d-flex justify-content-center align-items-center"
        style="border-radius: 8px;">
        <div *ngIf="!buscaBeto" class="col-12 col-lg-5 d-flex justify-content-center mb-3">
            <h1 class="ml-5 d-none d-lg-block font-weight-bold">{{"Selecione a data de visita" | translate}}</h1>
            <h4 class="d-lg-none font-weight-bold">{{"Seleciona a data de visita" | translate}}</h4>
        </div>

        <div *ngIf="!buscaBeto" class="col-12 col-lg-3 d-flex justify-content-center mb-4" style="column-gap: 0.75rem;">
            <label class="w-100 d-flex flex-column justify-content-center" style="font-family: sans-serif;">
                {{"Data da Visita" | translate}}
                <input autocomplete="off" class="form-control" type="date" nbInput 
                    [(ngModel)]="Busca.dataVisitaBetoCarrero" (ngModelChange)="Buscar()" />
            </label>
        </div>

        <app-view-loading *ngIf="carregando"></app-view-loading>
        <div class="mt-3" *ngIf="mensagemSucesso || mensagemErro.length > 0">
            <div class="col-md-12" *ngIf="mensagemSucesso">
                <nb-alert *ngIf="!carregando" status="success" (close)="limparMsg()" closable>{{mensagemSucesso |
                    translate}}
                </nb-alert>
            </div>
            <div class="col-md-12" *ngIf="mensagemErro.length > 0">
                <nb-alert status="danger" closable (close)="limparMsg()">
                    <ul>
                        <li *ngFor="let m of mensagemErro">
                            {{ m | translate }}
                        </li>
                    </ul>
                </nb-alert>
            </div>
        </div>

        <div *ngIf="!carregando" class="col-5 d-flex justify-content-center align-items-center botoes"
            style="column-gap: 0.8rem;">
            <span *ngFor="let item of TipoIngressos">
                <button *ngIf="item.active" class="btn {{item.selecionado ? 'btn-primary': 'btn-outline-primary'}}"
                    (click)="mudarLista(item)">{{item.value}}</button>
            </span>
        </div>

        <div *ngIf="temIngressosSelecionados && !carregando" class="row mt-3 mb-4 mt-lg-4">
            <div class="col-12 d-flex justify-content-center">
                <button class="btn d-none d-lg-block w-25 btn-primary" (click)="continuar()">Continuar</button>
                <button class="btn d-lg-none w-75 btn-primary" (click)="continuar()">Continuar</button>
            </div>
        </div>

        <div class="col-12 d-grid-4 d-lg-none mobile">
            <div class="card col-12 p-0 d-flex flex-column justify-content-center mb-3 mt-3"
                *ngFor="let ingresso of Lista">

                <img *ngIf="ingresso.Imagem != null" src="data:image/png;base64,{{ingresso.Imagem}}" class="mt-3"
                    alt="Imagem do ingresso">
                <img *ngIf="ingresso.Imagem == null" src="assets/img/betoCarrero/chapeuBetoCarrero.png" class="mt-3"
                    alt="Imagem do ingresso">

                <div class="card-body pl-4 pr-4 row">
                    <div class="col-12">
                        <h4 class="">{{ingresso.Titulo}}</h4>
                        <p class="card-text mt-auto">{{ingresso.DescricaoCliente}}</p>
                    </div>
                    <div class="col-lg-6"></div>
                    <div class="col-12 col-lg-5 d-flex flex-column align-items-center mt-auto">
                        <a (click)="abrirModal(detalhesIngressos, ingresso)" class="text-primary mt-2"
                            style="text-decoration: underline; font-size: 1.5rem; font-family: Roboto, 'Helvetica Neue', sans-serif;">+
                            Detalhes</a>
                        <div class="d-flex flex-column align-items-center ml-auto">
                            <p class="text-muted mb-0" style="font-size: Roboto, 'Helvetica Neue', sans-serif;">A partir
                                de:</p>
                            <h4 class="text-secondary">{{ingresso.MoedaListagem.Sigla}}
                                {{ingresso.ValorListagem.toFixed(2)}}</h4>
                        </div>
                    </div>
                    <div class="col-12 row m-0 d-flex text-center align-items-center">
                        <p class="col-12 m-0"
                            style="color: #000000; font-size: 1rem; font-size: Roboto, 'Helvetica Neue', sans-serif;">
                            Quantidade:</p>
                        <div class="col-12 d-flex justify-content-around align-items-center">
                            <button class="botoes-quantidade m-2 d-flex justify-content-center align-items-center"
                                [disabled]="quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] == 0 || quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] == null"
                                (click)="removeuIngresso(ingresso)"><i class="fas fa-minus text-primary"></i></button>
                            <input class="input-quantidade text-center" style="font-size: 20px;" type="text" disabled
                                [(ngModel)]="quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] == 0 || quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] == null? '0' : quantidadeIngressosSelecionados[ingresso.CodItemIntegrador]" />
                            <button class="botoes-quantidade m-2 d-flex justify-content-center align-items-center"
                                (click)="selecionouIngresso(ingresso)"><i class="fas fa-plus text-primary"></i></button>
                        </div>
                    </div>
                </div>
            </div>



        </div>
        <div class="col-12 row d-none d-lg-block desktop">
            <div class="col-12 row d-flex justify-content-around">
                <div class="card col-5 p-0 justify-content-center d-none d-lg-flex mb-3 mt-3" *ngFor="let ingresso of Lista">
                    <div class="card-body pl-4 pb-2 pr-3 pt-4 row">
                        <div class="col-12 row mb-4">
                            <div class="col-5 d-flex flex-column align-items-center justify-content-center ">
                                <img *ngIf="ingresso.Imagem != null" src="data:image/png;base64,{{ingresso.Imagem}}"
                                    class="" alt="">
                                <img *ngIf="ingresso.Imagem == null" src="assets/img/betoCarrero/chapeuBetoCarrero.png"
                                    class="" alt="">
                                <p class="mt-auto text-primary mt-2"
                                    style="text-decoration: underline; font-size: 20px; cursor: pointer;"
                                    (click)="abrirModal(detalhesIngressos, ingresso)">
                                    + Detalhes
                                </p>
                            </div>
                            <div class="col-7 d-flex flex-column h-100">
                                <h4 class="">{{ingresso.Titulo}}</h4>
                                <div class="mt-auto d-flex justify-content-between">
                                    <div class="mr-auto mt-auto">
                                        <p class="text-muted mb-0">A partir de</p>
                                        <h4 class="text-secundary">{{ingresso.MoedaListagem.Sigla}}
                                            {{ingresso.ValorListagem.toFixed(2)}}
                                        </h4>
                                    </div>
                                </div>
                                <div class="row d-flex justify-content-center align-items-center">
                                    <p class="col-5 m-0">Quantidade:</p>
                                    <div class="col-7 d-flex align-items-center">
                                        <button
                                            class="botoes-quantidade m-2 d-flex justify-content-center align-items-center"
                                            [disabled]="quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] == 0 || quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] ==  null"
                                            (click)="removeuIngresso(ingresso)"><i
                                                class="fas fa-minus text-primary"></i></button>
                                        <input class="input-quantidade text-center" style="font-size: 20px;" type="text"
                                            disabled
                                            [(ngModel)]="quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] == 0 || quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] == null ? 'o' : quantidadeIngressosSelecionados[ingresso.CodItemIntegrador]" />
                                        <button
                                            class="botoes-quantidade m-2 d-flex justify-content-center align-items-center"
                                            (click)="selecionouIngresso(ingresso)"><i
                                                class="fas fa-plus text-primary"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #detalhesIngressos>
            <div class="modal-header">
                <h3 id="modal-basic-title">{{passaporteDetalhes?.Titulo}}</h3>
                <button type="button" class="close btn btn-cancelar" aria-label="Close" (click)="fecharModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <h5>{{passaporteDetalhes?.DescricaoCliente}}</h5>
                        <p [innerHTML]="passaporteDetalhes?.EspecificacaoHtml"></p>
                    </div>
                </div>
            </div>

            <div class="modal-footer" style="display:block;">
                <div class="row">
                    <div class="col-2">
                        <button class="btn btn-cancelar" status="warning" type="submit" (click)="fecharModal()">{{
                            'Voltar' |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>