<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title"><span  *ngIf="hotel.ExigePrePagamento || QuartosComPrePagamento(hotel)" class="badge badge-pill badge-info">Exige Pré-Pagamento</span> Reserva de Hotel - {{hotel.Nome}}</h4>
</div>
<div class="modal-body" *ngIf="!carregando">
  <div class="row" *ngIf="mensagensErro?.length > 0">
    <div class="alert alert-danger">
      <ul>
        <li *ngFor="let mensagem of mensagensErro">{{mensagem}}</li>
      </ul>
    </div>
  </div>

  <div>
    <div>
      <p *ngIf="!politica && carregando">{{"Buscando Políticas" | translate}}</p>
      <p>{{politica?.Politica.Mensagem}}</p>
    </div>

    <div>
      <p [innerHTML]="politica?.Politica.MensagemFornecedor"></p>
    </div>
    <div>
      <p>{{"solicitações especiais ao hotel (pode haver custo)" | translate}}</p>
      <textarea [(ngModel)]="hotel.Observacao" cols="55" rows="5"></textarea>
    </div>

    <div>
      <div *ngIf="politica?.Politica.AlterouValor">
        <h5 style="color: red;">{{"ATENÇÃO o valor do hotel alterou!" | translate}}</h5>
        <p><strong>{{"Valor Atualizado" | translate}}: {{politica.Hotel.ValorTotalListagem}}</strong></p>
        <p><input type="checkbox" [(ngModel)]="politica?.Politica.CienteAlterouValor" status="danger">{{"Estou Ciente" | translate}}</p>
      </div>
    </div>
  </div>
</div>

<div class="modal-body" *ngIf="carregando">
  <app-loading-small></app-loading-small>
</div>

<div class="modal-footer">
  <div class="row" *ngIf="mensagemSucesso?.length > 0">
    <div class="alert alert-success">
      {{mensagemSucesso}}
    </div>
  </div>

  <div class="row">
    <div class="col text-end">
      <button class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="fechou.emit()">{{'Voltar' | translate}}</button>
      <button *ngIf="hotel.Status == 0" (click)="reservar()" class="btn btn-success" type="submit"
      [disabled]="!politica?.Politica.PoliticaValida ||  carregando || (politica?.Politica.AlterouValor && !politica?.Politica.CienteAlterouValor)">
        {{"Reservar" | translate}}
      </button>
    </div>
  </div>
</div>
