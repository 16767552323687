<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reservar carro</h4>
    <i class="fas fa-times" (click)="fecharModal()"></i>
  </div>
  <div class="modal-body">
  
  
    <div class="card">
      <div class="card-header">
        <h4>{{carro.Descricao}}</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div>
              <img class="img-fluid img-thumbnail" [src]="carro.Imagem" alt="">
            </div>
          </div>
          <div class="col-md-9">
            <div class="row" data-gutter="20">
              <div class="col-md-12">
                <p style="margin: 20px 0 20px;">
                  {{carro.ModeloNome}}
                </p>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <strong>{{'Ocupantes'}}:</strong> {{carro.Pax}}
                </div>
                <div class="col-md-4">
                  <strong>Núm. {{'portas'}}:</strong> {{carro.Portas}}
                </div>
                <div class="col-md-4">
                  <strong>{{'Bagagem'}}:</strong> {{carro.Bagagem}}
                </div>
                <div class="col-md-4">
                  <strong>{{'Ar Condicionado'}}:</strong> {{carro.TemAirCondicionado?"Sim":"Não"}}
                </div>
                <div class="col-md-6">
                  <strong>{{'Transmissão'}}:</strong> {{carro.TransmissaoTipo}}
                </div>
              </div>
            </div>
            <div class="row mt-2" data-gutter="20">
              <ul class="list-unstyled">
                <li *ngFor="let protecao of carro.Protecoes; index as i">
                  {{protecao.Titulo}} ({{protecao.Codigo}})
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-5">
            <p style="font-size: larger;">{{"Condutor (21 anos ou mais)" | translate}}</p>
          </div>
          <div class="col-sm-7 input-group has-calendar-icon">
            <!-- <select class="form-select" placeholder="Informe seu E-mail" name="condutor" [(ngModel)]="condutor">
              <option *ngFor="let c of condutores" [ngValue]="c">{{c.Nome}} {{c.Sobrenome}}</option>
            </select> -->
            <select class="form-select" name="condutor" [(ngModel)]="condutor">
              <option value="" [disabled]="true" [selected]="!condutor">{{'Informe seu E-mail' | translate}}</option>
              <option *ngFor="let c of condutores" [ngValue]="c">{{c.Nome}} {{c.Sobrenome}}</option>
          </select>
          </div>
        </div>
        <div class="row" *ngIf="mensagemDocumento" style="color:red">
          <div class="col-sm-12">
            <p>
              {{"Necessário cadastrar o documento para um dos passageiros e ter 21 anos ou mais." | translate}}
            </p>
          </div>
        </div>
        <div class="row mt-2" (keydown.enter)="$event.preventDefault()">
          <div class="col-md-4">
            <label class="label">{{ "CPF" | translate }}</label>
            <app-input>
              <input autocomplete="off" type="text" class="form-control input-sm" [(ngModel)]="condutor.CPF" name="inputCPF"
                required />
            </app-input>
          </div>
          <div class="col-md-4">
            <label class="label">{{ "E-mail" | translate }}</label>
            <app-input>
              <input autocomplete="off" required type="text" class="form-control input-sm" [(ngModel)]="condutor.Email"
                name="inputEmail" />
            </app-input>
          </div>
          <div class="col-md-4">
            <label class="label">{{ "Telefone" | translate }}</label>
            <app-input>
              <input autocomplete="off" required type="text" class="form-control input-sm" [(ngModel)]="condutor.Telefone"
                name="inputTelefone" />
            </app-input>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="card">
      <div class="card-header">
        <h4>Requisitos e condições de Locação</h4>
      </div>
      <div class="card-body">
        <h4>Como Alugar Contrato Política Privacidade</h4><br>
        <h5>BRASILEIROS</h5><br>
        • Ter idade mínima de 21 anos;<br>
        • Clientes com idade superior a 19 anos e inferior a 21 anos poderão efetuar a locação mediante o pagamento de
        taxa adicional denominada Locação Jovem;<br>
        • Locatário: Apresentar Carteira de Habilitação (CNH) definitiva, original e válida;<br>
        • Apresentar cartão de crédito, com emissão bancária, com chip e bandeira e limite disponível para
        pré-autorização, em nome do locatário ou responsável financeiro;<br>
        • Não possuir restrições financeiras ou de qualquer espécie;<br>
        • O Responsável Financeiro deverá estar presente no momento da abertura do contrato portando CPF e RG original, e
        não possuir restrições de qualquer espécie.<br><br>
        * Para inclusão de responsável financeiro será cobrança taxa adicional no contrato de locação.<br><br>
        <h5>ESTRANGEIROS</h5><br>
        • Ter idade mínima de 21 anos;<br>
        • Locatário: Apresentar Passaporte e Carteira de Habilitação originais e válidos;<br>
        • Apresentar Visto dentro do prazo permitido para permanência no Brasil, exceto para locatários oriundos do
        Mercosul;<br>
        • Apresentar cartão de crédito, com limite disponível para pré-autorização (valor semelhante à coparticipação da
        proteção contratada) em nome do locatário ou responsável financeiro;<br>
        • O Responsável Financeiro deverá estar presente no momento da abertura do contrato portando carteira de
        identidade válida, e não possuir restrições de qualquer espécie.<br>
        * Para inclusão de responsável financeiro será cobrança taxa adicional no contrato de locação.<br>
        GARANTIA PARA PAGAMENTO - AUTORIZAÇÃO CARTÃO DE CRÉDITO<br>
        • Verificação de crédito<br>
        A retirada do veículo está sujeita a uma análise de crédito do cliente no balcão da loja.<br>
        • Pré autorização: A quantia exigida de pré-autorização será bloqueada no cartão de crédito do locatário (não
        debitada) e desbloqueado após a devolução do veículo. O prazo para a liberação do limite depende do banco emissor
        do cartão de crédito.<br><br>
        • Valores<br><br>
        Com Contratação de Proteções ({{"a partir de" | translate}}):<br>
        Grupos AX, B, BS, BX, L e M: R$ 500,00 | Grupo C R$ 600,00 | Grupos D, E, FX, FY e FW: R$ 800,00 | Grupo F R$
        1.000,00 | Grupos G, H, HX, HN, HW, J, JC, K, Q, QX e SS: R$ 1.200,00 | Grupos SX, HY, KX, MJ, WX, HZ e ZX: R$
        2.000,00 | Grupos XE: R$ 3.000,00 | Grupos T: R$ 5.000,00.<br><br>
        * O valor de pré autorização será o valor acima ou o total da reserva, o que for maior.<br>
        Sem Contratação de Proteções* ({{"a partir de" | translate}}):<br>
        Grupos AX, B, BS e BX: R$ 12.000,00 | Grupos C, D, E, F, FX, FY, FW, G, H, HN, HX, HW, J, JC, Q, QX, SS, SX, L e
        M: R$ 18.000,00 | Grupo HY, KX, MJ: R$ 25.000,00 | Grupos T, WX e XE: R$ 50.000,00 | Grupos HZ e ZX: R$
        60.000,00.<br><br>
        * Clientes que optarem pelo seguro de seu cartão de crédito serão submetidos a estes valores de
        pré-autorização.<br><br>
        <h5>CONDUTORES ADICIONAIS</h5><br><br>
        • O LOCATÁRIO poderá autorizar exclusivamente até 03 (três) condutores adicionais para o VEÍCULO, devendo os
        referidos condutores adicionais atender às mesmas condições de idade, documentação e situação cadastral do
        LOCATÁRIO principal, exceto pela apresentação do cartão de crédito. Será aplicada a tarifa correspondente a este
        serviço no ato de sua contratação;<br><br>
        • Os condutores adicionais deverão ir à loja com o LOCATÁRIO para assinar o CONTRATO DE LOCAÇÃO;<br>
        • Em caso de infrações/multas de trânsito decorrentes do período da locação, o condutor principal será contatado
        para indicação do condutor infrator.
      </div>
    </div>
  </div>
  <div class="modal-footer" style="display:block">
    <div class="row" *ngIf="mensagensErro?.length > 0">
      <div class="alert alert-danger" style="width: 100%">
        <ul>
          <li *ngFor="let mensagem of mensagensErro">{{ mensagem | translate }}</li>
        </ul>
      </div>
    </div>
    <div class="row" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
      <div class="alert alert-success" style="width: 100%">
        {{ mensagemSucesso | translate }}
      </div>
    </div>
  
    <div class="w-100 text-center">
      <button *ngIf="carro.Status == 0 && !mensagemDocumento" [disabled]="carregando || verificarDocumento()" (click)="reservar()"
        class="btn btn-success" type="submit">{{"Concordar com os termos e reservar" | translate}}</button>
    </div>
  </div>
  