import { Component, Input, OnInit } from '@angular/core';
import { ItemLamina, Paginacao, Steps, PagamentoLamina, Laminas } from "../../model"
import { LoginService, Util } from "../../services";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { API } from 'src/app/app.config';
import { LaminasService } from 'src/app/services/laminas.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-laminas',
  templateUrl: './laminas.component.html',
  styleUrls: ['./laminas.component.scss']
})

export class LaminasComponent implements OnInit {

  API = API;
  @Input() Reserva: any;

  indexStep: number = 0;

  Steps: Steps = new Steps();
  paginacao: Paginacao = new Paginacao();
  itemLamina: ItemLamina = new ItemLamina();
  paginacaoTemplate: Paginacao = new Paginacao();
  pagamentoLamina: PagamentoLamina = new PagamentoLamina();

  mensagemErro: string = '';
  parametroBusca: string = '';

  carregando: boolean = false;
  // tabHotel: Boolean = true;
  // tabAereo: Boolean = false;
  permissaoCadastro: boolean = false;

  permissoes: any;
  imgDownload: any;
  formaPagamento: any;
  FormasPagamento: any;
  condicaoPagamento: any;
  imagensLamina = [];
  imagensLaminaUnsplash = [];
  templatesLamina = [];

  buscaApi: boolean = true;

  traducoesDisponiveis: string[] = ["pt", "es", "en"];
  traducaoEscolhida: string = "pt";

  mostraValorTotal: Boolean = false;

  constructor(private httpCliente: HttpClient, private laminasService: LaminasService, private modalService: NgbModal, private loginService: LoginService) { }

  ngOnInit() {
    this.itemLamina = new ItemLamina();
    this.itemLamina.AereoId = 0;
    this.itemLamina.HotelId = 0;
    this.obterPermissoes();
    let usuario = JSON.parse(window.localStorage.getItem('loggedUser'));
    this.itemLamina.Traducao = usuario.Agencia ? usuario.Agencia.Idioma : "pt";
  }

  selecionarImagemUnsplash(item) {
    if (this.itemLamina.ImagemURL == item && !this.buscaApi) {

      this.itemLamina.ImagemURL = null;
      this.Steps.FourthStep = false;
      this.itemLamina.buscaApi = true;

    } else if (this.itemLamina.ImagemURL != item && !this.buscaApi) {
      this.itemLamina.ImagemURL = item;
      this.itemLamina.buscaApi = false;
      this.Steps.FourthStep = true;
    };
  }

  obterPermissoes() {
    let permi = window.localStorage.getItem("permission");
    if (permi != null) {
      this.permissoes = JSON.parse(atob(permi));
      this.permissaoCadastro = this.permissoes.indexOf("escreve_laminas") > -1 || this.permissoes.indexOf("root") > -1;
    }
  }

  saveFile() {
    var a = document.createElement('a');
    a.href = this.imgDownload;
    a.download = this.itemLamina.HotelId + "-" + this.itemLamina.AereoId + "-" + this.itemLamina.ImagemId + ".jpg";
    document.body.appendChild(a);
    a.click();
  }

  selecionarHotel(id: number, valor: any, Moeda: any, Adt: number, nomeHotel: string) {
    if (this.itemLamina.HotelId == id) {
      this.itemLamina.HotelId = 0;
      this.pagamentoLamina.ValorHotel = undefined;
      this.pagamentoLamina.CambioPagamento = null;
      this.itemLamina.NomeHotel = "";
      this.Steps.FirstStep = false;
    } else {
      this.itemLamina.HotelId = id;
      this.pagamentoLamina.ValorHotel = valor;
      this.pagamentoLamina.QntdAdtHotel = Adt;
      this.pagamentoLamina.CambioPagamento = Moeda;
      this.itemLamina.NomeHotel = nomeHotel;
      this.Steps.FirstStep = true;
    }
  }

  selecionarAereo(id: number, valor: any, taxas: any, Adt: number) {
    if (this.itemLamina.AereoId == id) {
      this.itemLamina.AereoId = 0;
      this.pagamentoLamina.ValorAereo = undefined;
      // this.Steps.FirstStep = false;
    } else {
      this.itemLamina.AereoId = id;
      this.pagamentoLamina.QntdAdtAereo = Adt;
      this.pagamentoLamina.ValorAereo = (parseFloat(valor) - parseFloat(taxas));
      // if (this.itemLamina.HotelId !== 0) {
      //   this.Steps.FirstStep = true;
      // }
    }
  }

  selecionarImagem(id: number) {
    if (this.itemLamina.ImagemId == id && this.buscaApi) {
      this.itemLamina.ImagemId = null;
      this.itemLamina.buscaApi = true;
      this.Steps.FourthStep = false;
    } else if (this.itemLamina.ImagemId != id && this.buscaApi) {
      this.itemLamina.ImagemId = id;
      this.itemLamina.buscaApi = true;
      this.Steps.FourthStep = true;
    }
  }

  selecionarTemplate(TemplateId: number) {
    if (this.itemLamina.TemplateId == TemplateId) {
      this.itemLamina.TemplateId = null;
      this.Steps.ThirdStep = false;
    } else {
      this.itemLamina.TemplateId = TemplateId;
      this.Steps.ThirdStep = true;
    }
  }

  bucarFormasPagamento() {
    this.mensagemErro = '';
    this.carregando = true;

    this.pagamentoLamina.ValorPorPessoa = false;

    parseFloat(this.pagamentoLamina.ValorAereo);
    parseFloat(this.pagamentoLamina.ValorHotel);

    if (this.pagamentoLamina.ValorAereo == undefined) {
      this.pagamentoLamina.ValorTotal = this.pagamentoLamina.ValorHotel.toFixed(2);
    } else {
      this.pagamentoLamina.ValorTotal = (this.pagamentoLamina.ValorAereo + this.pagamentoLamina.ValorHotel).toFixed(2);
    }

    let busca = this.laminasService.formasPagamento(this.Reserva.Id);
    busca.subscribe(
      (dados) => {
        this.carregando = false;
        this.FormasPagamento = dados.FormasPgto;
      },
      (erros) => {
        this.carregando = false;
        console.log(erros);
        this.mensagemErro = "Erro ao buscar pagamentos no servidor, entre em contato com o suporte!";
      }
    )
  }

  //Faz alteração no valor total, se for valor por adulto divide pela quantidade de adultos.
  alterarFormaPagamento() {
    parseFloat(this.pagamentoLamina.ValorAereo);
    parseFloat(this.pagamentoLamina.ValorHotel);
    if (this.pagamentoLamina.ValorPorPessoa == false) {
      if (this.pagamentoLamina.ValorAereo == undefined) {
        this.pagamentoLamina.ValorTotal = this.pagamentoLamina.ValorHotel;
      } else {
        this.pagamentoLamina.ValorTotal = (this.pagamentoLamina.ValorAereo + this.pagamentoLamina.ValorHotel);
      }
    }
    else {
      if (this.pagamentoLamina.ValorAereo == undefined) {
        this.pagamentoLamina.ValorTotal = (this.pagamentoLamina.ValorHotel / this.pagamentoLamina.QntdAdtHotel);
      } else {
        this.pagamentoLamina.ValorTotal = ((this.pagamentoLamina.ValorAereo + this.pagamentoLamina.ValorHotel) / this.pagamentoLamina.QntdAdtHotel);
      }
    }
  }

  selecionouCondicoes(item) {
    this.mostraValorTotal = true;
    this.pagamentoLamina.Parcelas = item.Parcelas;
    this.pagamentoLamina.Coeficiente = item.Coeficiente;
    this.obterValorParcelas();
    this.Steps.SecondStep = true;
  }

  obterValorParcelas() {
    this.pagamentoLamina.ValorParcelas = (this.pagamentoLamina.ValorTotal * this.pagamentoLamina.Coeficiente);
  }

  buscarImagensFundoPagina(pagina) {
    this.paginacao.NumeroPagina = pagina;
    this.buscarImagensFundo();
  }

  buscarImagensFundo() {
    this.obterValorParcelas();
    this.mensagemErro = '';
    this.carregando = true;
    let busca = this.laminasService.ListaPorParametroPaginada(this.parametroBusca, this.paginacao.NumeroPagina);
    busca.subscribe(
      dados => {
        this.carregando = false;
        this.imagensLamina = dados.Items;

        this.imagensLamina.forEach(Lamina =>
          Lamina.URL = Util.pathImagemApi(Lamina.URL))

        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
      },
      erro => {
        this.carregando = false;
        this.imagensLamina = [];
        console.log(erro);
        this.parametroBusca = '';
        this.mensagemErro = "Erro ao buscar imagem no servidor, entre em contato com o suporte!";
      },
    );
  }



  buscarImagensUnsplash() {
    this.carregando = true;
    this.imagensLaminaUnsplash = [];
    let busca = this.laminasService.ListarImagensUnsplash(this.parametroBusca, 1);
    busca.subscribe(
      dados => {
        dados.forEach(x => {
          
          let item: Laminas = new Laminas();

          item.Id = x.id;
          item.Nome = x.alt_description;
          item.URL = x.urls.raw + "&fit=scale&w=1080&h=1920";
          this.imagensLaminaUnsplash.push(item);
        });
        this.carregando = false;
      },
      erros => {
        this.carregando = false;
        this.mensagemErro = erros.error.mensagem;
      }
    )
  }

  alterarFormaBusca(event) {
    if (!event) {
      this.buscarImagensUnsplash();
    }
  }

  buscarImagensTemplatePagina(pagina) {
    this.paginacaoTemplate = pagina;
    this.buscarImagensTemplate();
  }

  buscarImagensTemplate() {
    this.mensagemErro = "";
    this.carregando = true;
    let busca = this.laminasService.ListaTemplatePaginada(this.paginacaoTemplate.NumeroPagina);
    busca.subscribe(
      dados => {
        this.carregando = false;
        this.templatesLamina = dados.Items;

        this.templatesLamina.forEach(TemplateURL => {
          TemplateURL.URL = Util.pathImagemApi(TemplateURL.URL)
        });

        this.paginacaoTemplate.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacaoTemplate.TotalItens = dados.MetaData.TotalItens;
        this.paginacaoTemplate.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacaoTemplate.TotalPaginas = dados.MetaData.TotalPaginas;
      },
      erro => {
        this.carregando = false;
        this.templatesLamina = [];
        console.log(erro);
        this.mensagemErro = "Erro ao buscar templates no servidor, entre em contato com o suporte!";
      },
    );
  }

  gerarImagemHTML() {
    this.mensagemErro = '';
    this.carregando = true;
    this.imgDownload = '';
    //Manda os dados para serem tratados no backend
    let busca = this.laminasService.gerarImagemHTML(this.itemLamina, this.pagamentoLamina);
    busca.subscribe(
      (dados) => {
        this.imgDownload = dados;
        this.carregando = false;
      },
      (erros) => {
        this.carregando = false;
        console.log(erros);
        this.mensagemErro = "Erro ao gerar imagem no servidor, entre em contato com o suporte!";
      }
    )
  }

  fecharModal() {
    this.modalService.dismissAll();
  }
}
