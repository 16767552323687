import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { Orcamento, Quarto } from '../../../model';
import { NgbModal, NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
// import { nbPopoverDirective } from '@nebular/theme';

import { ReservaService } from '../../../services';
import { NavigationStart, Router } from '@angular/router';
import { BetoCarrero } from '../../../model/turismo/beto-carrero.model';

@Component({
    selector: 'beto-carrero-pre-orcamento',
    templateUrl: './beto-carrero-pre-orcamento.component.html',
    styleUrls: ['./beto-carrero-pre-orcamento.component.scss']
})
export class BetoCarreroPreOrcamentoComponent implements OnInit {

    // @ViewChildren("") popovers: QueryList<NbPopoverDirective>;
    modalRef: NgbModalRef;
    modalQuartoRef: NgbModalRef;

    constructor(
        private modalservice: NgbModal,
        private reservaService: ReservaService,
        private _router: Router
    ) { }

   @Input() Orcamento: any;
   @Input() Busca: any;
   @Output() removeu = new EventEmitter<BetoCarrero>();
   @Output() avancar = new EventEmitter<any>();
   @Output() voltou = new EventEmitter<any>();
   PopOver;

   Lista: BetoCarrero[] = [];
   quarto: Quarto = new Quarto();
   QtdMaxAdultos: number;

   orcamentoSalvo: boolean = true;

   mensagemErro: string[] = [];
   mensagemSucesso: string = "";

   ngOnInit() {
    this.Lista = this.Orcamento.BetoCarreroIngressos;
    if (this._router.url == "/listagem/pre-orcamento") {
    // if (this._router.url == "/listagem/orcamento") {
        this.orcamentoSalvo = false;
    }
   }

   removerDoOrcamento(ingresso: BetoCarrero) {
    const indexToRemove = this.Orcamento.BetoCarreroIngressos.findIndex(
        x => x.CodItemIntegrador === ingresso.CodItemIntegrador
    );

    if (indexToRemove !== -1) {
        this.Orcamento.BetoCarreroIngressos.splice(indexToRemove, 1);
        this.Lista = this.Orcamento.BetoCarreroIngressos;
        this.orcamentoSalvo = false;
    }

    this.removeu.emit(this.Orcamento.BetoCarreroIngressos);
   }

   abrirModal(content, quarto = null) {
    this.quarto = quarto;
    this.modalRef = this.modalservice.open(content, { centered: true, size: "md"});
   }

   fecharModal() {
    this.modalRef.close();
  }

   abrirModalQuarto(content, quarto = null) {
    this.quarto = quarto;
    this.modalQuartoRef = this.modalservice.open(content, { centered: true, size: "md" });
  }

   fecharModalQuarto() {
    this.modalQuartoRef.close();
   }

   salvarOrcamento() {
    delete this.mensagemErro;
    this.Orcamento.quartos = this.Busca.quartos;
    this.Orcamento.AgenciaId = this.Busca.AgenciaId;

    if (this.Busca.novoOrcamento) {

        this.reservaService.salvarOrcamento(this.Orcamento).subscribe(
            dados => {
                this._router.navigate([`/orcamento/${dados.Id}`]);
                // this._router.navigate([`/turismo/orcamento/${dados.Id}`]);
            },
            erros => {
                console.log(erros);

                this.mensagemErro = this.errorHandler(erros);
            }
        );
    } else {
        this.reservaService
        .adicionarItemOrcamento(this.Orcamento, this.Busca.orcamentoId)
        .subscribe(
            dados => {
                this._router.navigate([
                    `/orcamento/${this.Busca.orcamentoId}`
                ]);
            },
            erros => {
                console.log(erros);
                this.mensagemErro = this.errorHandler(erros);
            }
        );
    }
   }

   selecionarQuarto(quarto, p) {
    this.quarto = quarto;
    this.PopOver = p;
   }

   ajustaQtdeIdades(evento, quarto) {
    setTimeout(() => {
        while (quarto.idadesCriancas.length != evento) {
            if (quarto.idadesCriancas.length > evento) {
                quarto.idadesCriancas.pop();
            } else {
                quarto.idadesCriancas.push(0);
            }
        }
    }, 100);
   }

   quantidadePessoas() {
    let qtd = 0;
    this.Busca.quartos.forEach(quarto => {
        qtd += quarto.qtdeAdultos + quarto.qtdeIdosos + quarto.qtdeCriancas;
    });
    return qtd;
   }

   adicionarQuarto() {

    setTimeout(() => this.Busca.quartos.push(new Quarto()), 100);
   }

   removerQuarto() {
    setTimeout(() => this.Busca.quartos.pop(), 100);
   }

   confirmar() {
    window.sessionStorage.setItem("busca", JSON.stringify(this.Busca));
    this.modalservice.dismissAll();
   }

   rotaAnterior() {
    this.voltou.emit();
   }

   rotaAvancar() {
    this.avancar.emit();
   }

   public errorHandler(erro): string[] {

    let mensagem = [];
    switch (erro.status) {
        case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
        case 400:

            if (erro.error && erro.error.mensagem)
                mensagem.push(erro.error.mensagem);
            else
                mensagem.push("Houve algum erro na execução, tente novamente!");

            break;
        case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
        case 404: mensagem.push(erro.error.mensagem); break;
        case 406: mensagem.push(erro.error.mensagem); break;
        case 500: mensagem.push(erro.error.ExceptionMessage); break;
        default: mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
        mensagem.push(erro.error.mensagem);
    }
    return mensagem;

   }

   limparMsg() {
    this.mensagemErro = [];
    this.mensagemSucesso = "";
   }

//    concluirSelecaoQuarto(item) {
//      this.popovers.forEach((pop) => {
//      pop.hide();
//      });
//    }

   voltarOrcamento() {
    this._router.navigate([`/orcamento/${this.Busca.orcamentoId}`]);
   }
}

