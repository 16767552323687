import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BuscaBetoCarreroRQ } from "../../model/turismo/beto-carrero.model";
import { API } from "../../app.config";

@Injectable()
export class BetoCarreroService {
  constructor(private http: HttpClient) { }

  buscarIngressoPorId(id: number): Observable<any> {
    return this.http.get<any>(`${API}/api/BetoCarrero/${id}`);
  }

  buscarIngressosBeto(BuscaBetoCarreroRQ: BuscaBetoCarreroRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/BetoCarrero/Busca`, BuscaBetoCarreroRQ, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  reservarIngresso(reservar: any): Observable<any> {
    return this.http.post<any>(`${API}/api/BetoCarrero/Reservar`, reservar);
  }

  buscaPolitica(betoId: number, reservaId: number): Observable<any> {
    return this.http.patch<any>(`${API}/api/BetoCarrero/PoliticaCancelamento/${reservaId}/${betoId}`, null);
  }

  cancelarReservaIngresso(betoId: any): Observable<any> {
    return this.http.delete<any>(`${API}/api/BetoCarrero/Cancelar/${betoId}`);
  }

  removerIngresso(betoId: number): Observable<any> {
    return this.http.delete<any>(`${API}/api/BetoCarrero/Delete/${betoId}`);
  }

  alterarIngresso(alteracao: any): Observable<any> {
    return this.http.put<any>(`${API}/api/BetoCarrero/alterar`, alteracao, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  ListaVendasBetoCarreroFornecedor(vendasRequests: any, pagina: Number, itensPagina: number = 0): Observable<any> {
    return this.http.post<any>(`${API}/api/BetoCarrero/Lista-Vendas/?Pagina=${pagina}&ItensPorPagina=${itensPagina}`, vendasRequests, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  vendasCancelarIngresso(localizador: number): Observable<any> {
    return this.http.delete<any>(`${API}/api/BetoCarrero/VendasCancelar/${localizador}`);
  }
}
