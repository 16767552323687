import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/services';
import { FAVICONLIGHT } from 'src/app/app.config';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    faviconlight = FAVICONLIGHT;
    isLoading: Subject<boolean> = this.loaderService.isLoading;

    color = 'primary';
    mode = 'indeterminate';
    value = 50;

    constructor(private loaderService: LoaderService) { }

    ngOnInit() {
    }

}