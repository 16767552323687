import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BuscaServicoRQ } from 'src/app/model';
import { BACKGROUNDIMAGE } from 'src/app/app.config';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-orcamento-servico-card',
  templateUrl: './orcamento-servico-card.component.html',
  styleUrls: ['./orcamento-servico-card.component.scss']
})

export class OrcamentoServicoCardComponent {
  constructor(private sanitizer: DomSanitizer) { }
  backgroundimage = BACKGROUNDIMAGE;

  @Input() index: any;
  @Input() servico: any;
  @Input() pacote: any;

  @Input() Orcamento: any;
  @Input() busca: any;

  @Input() usuario: any;
  @Input() buscaRQ: BuscaServicoRQ;
  @Input() selecionar = true;
  @Output() selecionouServico = new EventEmitter<any>();
  @Output() removeuHotel = new EventEmitter<any>();

  sanitizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
}
}
