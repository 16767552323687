import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-orcamento-carro-card',
    templateUrl: './orcamento-carro-card.component.html',
    styleUrls: ['./orcamento-carro-card.component.scss']
})
export class OrcamentoCarroCardComponent implements OnInit {

    @Input() carro: any;

    protecaoSelecionada: any;

    constructor() { }

    ngOnInit(): void {
        this.protecaoSelecionada = this.carro.Protecoes.filter(x => x.selecionado);
        console.log(this.protecaoSelecionada);
    }
}
