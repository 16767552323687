import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PacotelstService } from 'src/app/services/turismo/pacotelst.service';

@Component({
    selector: 'app-reserva-pacote',
    templateUrl: './reserva-pacote.component.html',
    styleUrls: ['./reserva-pacote.component.css']
})
export class ReservaPacoteComponent implements OnInit {


    @Output() alterou = new EventEmitter<any>();
    @Input() Pacote: any;
    carregando = false;
    mensagensErro: any[];
    mensagemSucesso: string;
    reservado = false;

    constructor(private pacoteService: PacotelstService, private modalService: NgbModal) { }

    ngOnInit() {
        console.log(this.Pacote);
    }

    limparMensagens() {
        delete this.mensagemSucesso;
        delete this.mensagensErro;
    }

    reservar() {
        const confirmar = window.confirm(
            `Aceite de Termos e Condições\n\nAo prosseguir, você confirma que revisou todas as informações do seu pacote, incluindo a política do serviço, o número de pessoas, a configuração dos serviços escolhidos, a localização e os detalhes do pacote selecionado.\n\nAo clicar em "Aceitar", você declara que está ciente e de acordo com os termos e condições aplicáveis, e que as informações fornecidas estão corretas. Se houver necessidade de correções, faça as alterações antes de concluir a reserva do seu pacote.`
        );
        if (confirmar) {
            delete this.mensagensErro;
            delete this.mensagemSucesso;
            this.carregando = true;
            this.pacoteService.reservarPacoteDestino(this.Pacote.Id).subscribe(
                dados => {
                    this.reservado = true;
                    Object.assign(dados, this.Pacote);
                    this.reservado = true;
                    this.mensagemSucesso = 'Pacote reservado!';
                    this.alterou.emit(this.Pacote);
                    this.carregando = false;

                }, erro => {
                    this.reservado = true;
                    this.carregando = false;
                    console.log(erro);
                    this.mensagensErro = this.errorHandler(erro);
                });
        }
    }
    fecharModal() {
        this.modalService.dismissAll();
    }

    public errorHandler(erro): string[] {

        let mensagem = [];
        switch (erro.status) {
            case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
            case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
            case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
            case 404: mensagem.push('Solicitação inválida!'); break;
            case 406: mensagem.push(erro.error.mensagem); break;
            case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'); break;
            default: mensagem.push(erro.statusText);
        }
        if (erro.error != null && erro.error.mensagem != null) {
            mensagem = [erro.error.mensagem];
        }
        return mensagem;

    }

}
