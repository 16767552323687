import { Component, Input, OnInit, HostListener } from '@angular/core';
import * as moment from 'moment';
import { BuscaComponent } from 'src/app/shared/busca/busca.component';
import { Aeroporto, Cidade, LojasCarros, TIPOBUSCAAEREO } from 'src/app/model';
import { SLIDERHOME, FRASELOGIN, FRASEHOME, PROJETO, USA_AEREO, USA_CARRO, SLIDERHOMEDESKTOP, USA_BETO } from 'src/app/app.config';
import { CidadeService, AeroportoService, LoginService, PaisService } from 'src/app/services';
import { CarroService } from 'src/app/services/turismo/carro.service'
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

export const BUSCA = 'busca';
export const ORCAMENTO = 'orcamentousuario';

// tslint:disable: no-use-before-declare

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent extends BuscaComponent implements OnInit {

  i: number;
  sliderhome = SLIDERHOME;
  sliderhomeDesktop = SLIDERHOMEDESKTOP;
  fraselogin = FRASELOGIN;
  frasehome = FRASEHOME;
  // CheckBoxes Top
  aereo = true;
  hotel = true;
  servico = true;
  beto = true;
  usaAereo = USA_AEREO;
  usaCarro = USA_CARRO;
  usaBetoCarrero = USA_BETO;
  USA_BETO: boolean = USA_BETO;


  // Radio
  aereoVolta: boolean;
  aereoIda: boolean;
  aereoTrecho: boolean;

  // CheckBoxes Bottom
  vooDireto = false;
  promo = false; bagagem = false;
  dataAtual: any;

  FormControl = ''
  // Variáveis no Storage
  agencia = localStorage.getItem('g8h7d8');
  buscaTipoAereo: any;
  active = false;
  paises = [];
  adicionar = false;
  carregando: boolean = false;

  innerWidth: number = window.innerWidth;
  @HostListener('window:resize', ['$event'])

  onResize() {
    this.innerWidth = window.innerWidth;
  }

  get isDesktop() {
    return this.innerWidth <= 1600;
  }

  ngOnInit() {
    this.active = true;
    this.titleService.setTitle('Portal ' + PROJETO)
    this.usuario = JSON.parse(localStorage.getItem('loggedUser'));
    this.dataAtual = moment().format('YYYY-MM-DD');
    this.buscaTipoAereo = sessionStorage.getItem(BUSCA);
    this.busca.tipoBusca.aereo = this.usaAereo;
    this.busca.tipoBusca.hotel = true;
    this.busca.tipoBusca.ticket = true;
    this.busca.tipoBusca.beto = false;
    this.id = this.route.snapshot.params.id;
    if (this.id !== undefined) {
      this.adicionar = true;
    } else {
      this.adicionar = false;
    }
    const buscaStoraged = window.sessionStorage.getItem('busca');
    if (buscaStoraged) {
      let buscaDecoded = this.busca.decodeBusca(buscaStoraged)
      this.busca.nrnoites = buscaDecoded.nrnoites
    }

    if (this.usuario?.Agencia?.EmpresaId == 3) {
      this.usaBetoCarrero = false;
    }

    this.busca.dataVisitaBetoCarrero = moment().add(1, "days").toString();

    this.aereoVolta = true;
  }


  ngAfterViewInit() {
    const buscaStoraged = window.sessionStorage.getItem('busca');

    if (buscaStoraged !== null) {
      this.busca = this.busca.decodeBusca(buscaStoraged);
      this.busca.trechos = this.busca.trechos.map(x => {
        x.dataIda = moment(x.dataIda).format('YYYY-MM-DD');
        x.dataVolta = moment(x.dataVolta).format('YYYY-MM-DD');
        x.minDataIda = moment(x.minDataIda).format('YYYY-MM-DD');
        x.minDataVolta = moment(x.minDataVolta).format('YYYY-MM-DD');
        // x.minDataVisitaBeto =  moment(x.minDataVisitaBeto).format('YYYY-MM-DD');
        return x;
      });

      this.busca.dataOrigem = moment(this.busca.dataOrigem).format('YYYY-MM-DD');
      this.busca.dataDevolucao = moment(this.busca.dataDevolucao).format('YYYY-MM-DD');
    }


    let paiscache = window.sessionStorage.getItem("zfjd87");
    if (paiscache != null) {
        this.setarPaisAgencia();
    } else {
        setTimeout(() => this.setarPaisAgencia(), 2100);
  }

    this.busca.destinoCodigo = '';
  }


  buscaPaisDebounce(val: string) {
    clearTimeout(this.timerPais);
    this.timerPais = setTimeout(() => {
      this.buscaPais(val);
    }, 600);
  }

  timerPais = null;

  buscaPais(val: string) {
    this.paisService.buscarPorParametro(val, 1).subscribe(
      (dados) => {
        this.paises = dados.Items.map((x) => {
          x.display_field = x.Nome;
          return x;
        });
      },
      (erro) => {
        console.log(erro);
        this.paises = [];
      }
    );
  }

  selecionaPaisNacionalidade(item) {
    this.busca.nacionalidadePax = item;
    this.busca.quartos = this.busca.quartos.map((x) => {
      x.nacionalidadePaxQuarto = item.Iata;
      return x;
    });

    let paiscache = window.localStorage.getItem("zfjd87");

    if (paiscache != null) {
      window.localStorage.removeItem("zfjd87");
      window.localStorage.setItem("zfjd87", btoa(JSON.stringify(item)));
    } else {
      window.localStorage.setItem("zfjd87", btoa(JSON.stringify(item)));
    }
  }


  setarPaisAgencia() {
    let user = window.localStorage.getItem("loggedUser");
    if (user != null) {
      let u = JSON.parse(user);

      let paiscache = window.localStorage.getItem("zfjd87");

      if (paiscache != null) {
        let pais = JSON.parse(atob(paiscache));

        this.busca.quartos = this.busca.quartos.map((x) => {
          x.nacionalidadePaxQuarto = pais.Iata;
          return x;
        });
        this.busca.nacionalidadePax = pais;
        this.busca.nacionalidadePax.Nome = pais.Nome;
      } else {
        if (u.Agencia != null && u.Agencia != undefined) {
          this.cidadeService.buscarPorId(u.Agencia.CidadeId).subscribe(
            (dados) => {
              this.busca.quartos = this.busca.quartos.map((x) => {
                x.nacionalidadePaxQuarto = dados.Items[0].Estado.Pais.Iata;
                return x;
              });
              this.busca.nacionalidadePax = dados.Items[0].Estado.Pais;
              this.busca.nacionalidadePax.Nome =
                dados.Items[0].Estado.Pais.Nome;

              window.localStorage.setItem(
                "zfjd87",
                btoa(JSON.stringify(dados.Items[0].Estado.Pais))
              );
            },
            (erro) => {
              console.log(erro);
            }
          );
        } else if (u.Empresa != null && u.Empresa != undefined) {
          this.cidadeService.buscarPorId(u.Empresa.CidadeId).subscribe(
            (dados) => {
              this.busca.quartos = this.busca.quartos.map((x) => {
                x.nacionalidadePaxQuarto = dados.Items[0].Estado.Pais.Iata;
                return x;
              });
              this.busca.nacionalidadePax = dados.Items[0].Estado.Pais;
              this.busca.nacionalidadePax.Nome =
                dados.Items[0].Estado.Pais.Nome;

              window.localStorage.setItem(
                "zfjd87",
                btoa(JSON.stringify(dados.Items[0].Estado.Pais))
              );
            },
            (erro) => {
              console.log(erro);
            }
          );
        }
      }
    }
  }

  setAgencia() {
    let usuario = JSON.parse(localStorage.getItem('loggedUser'));
    let agencia = localStorage.getItem('g8h7d8');

    if (usuario.Agencia == (null || undefined)){
      localStorage.setItem('g8h7d8', btoa(JSON.stringify(agencia)));
    } else if (usuario.Agencia != (null || undefined)){
      localStorage.setItem('g8h7d8', btoa(JSON.stringify(usuario.Agencia)));
    }
  }

  verificaAgencia() {
    let agencia = window.localStorage.getItem('g8h7d8');

          if (agencia != null) {
              agencia = JSON.parse(atob(agencia));
              this.temagencia = agencia;
          }

          if (agencia != null || agencia != undefined || this.usuario.AgenciaId != null) {
              this.buscar();
          } else {
            alert("Selecione uma agência para continuar!")
          }


  }

  negar(item) {
    switch(item) {
      case 'aereo':
        this.busca.tipoBusca.aereo = !this.busca.tipoBusca.aereo;
      break;

      case 'hotel':
        this.busca.tipoBusca.hotel = !this.busca.tipoBusca.hotel;
      break;

      case 'servico':
        this.busca.tipoBusca.ticket = !this.busca.tipoBusca.ticket;
      break;

      case 'vooDireto':
        this.vooDireto = !this.vooDireto;
      break;

      case 'promo':
        this.promo = !this.promo;
      break;

      case 'bagagem':
        this.bagagem = !this.bagagem;
      break;

      case 'beto':
        this.busca.tipoBusca.beto = !this.busca.tipoBusca.beto;
      break;
    }
  }

  limpaPesquisa() {
    sessionStorage.removeItem(BUSCA);
    sessionStorage.removeItem(ORCAMENTO);
  }

  limpaPesquisaCompleta() {
    this.limpaPesquisa();
    this.setUmTrecho();
    alert('Pesquisa Limpa')

  }

  zeraValores() {
    this.i = 99;
    this.qntTrechos = 99;

    while (this.i !== 0) {
      this.removerTrecho(this.i);
      this.i--;
      this.qntTrechos--;
    }

    this.i = undefined;
    this.qntTrechos = 0;
  }

  tipoAereo(item) {
    this.limpaPesquisa();
    switch (item) {
      case('ida'):
        this.aereoIda = true;
        this.aereoVolta = false;
        this.aereoTrecho = false;


          this.busca.tipoBuscaAereo = TIPOBUSCAAEREO.ida;

      break;

      case ('ida-volta'):
        this.aereoIda = false;
        this.aereoVolta = true;
        this.aereoTrecho = false;


          this.busca.tipoBuscaAereo = TIPOBUSCAAEREO.idaVolta;


      break;

      case ('trechos'):
        this.aereoIda = false;
        this.aereoVolta = false;
        this.aereoTrecho = true;

        this.busca.tipoBuscaAereo = TIPOBUSCAAEREO.multiplosTrechos;

        if (this.busca.trechos.length < 1) {
          this.adicionarTrecho();
        }
      break;
    }

    this.zeraValores();
    if (this.busca.trechos.length < 1) {
      this.adicionarTrecho();
    }
  }

  setUmTrecho() {
    while (this.busca.trechos.length > 1) {
      this.busca.trechos.pop();
    }
    this.busca.trechos[0].cidadeOrigem = new Cidade();
    this.busca.trechos[0].cidadeDestino = new Cidade();
    this.busca.trechos[0].AeroportoPartida = new Aeroporto();
    this.busca.trechos[0].AeroportoChegada = new Aeroporto();
    this.busca.localOrigem = new LojasCarros();
    this.busca.localDevolucao = new LojasCarros();

    this.busca.trechos = this.busca.trechos.map(x => {
      x.dataIda = moment().format('YYYY-MM-DD');
      x.dataVolta = moment().format('YYYY-MM-DD');
      // x.dataVisitaBeto = moment().format('YYYY-MM-DD');
      x.minDataIda = moment().format('YYYY-MM-DD');
      x.minDataVolta = moment().format('YYYY-MM-DD');
      // x.minDataVisitaBeto =  moment().format('YYYY-MM-DD');
      return x;
    });
  }

  lojas = [];
  minDate: any = moment().format('YYYY-MM-DD');
  buscaLojas(val: string) {
    this.carroService.buscarPorParametro(val, 1).subscribe(
      dados => {
        this.lojas = dados.Items.map(x => {
          let tipo = "";
          if (x.Tipo == "A"){
            tipo = "Aeroporto";
          } else if (x.Tipo == "C"){
            tipo = "Cidade";
          }

          x.display_field = `${x.Sigla} - ${x.Nome} / ${tipo}`;
          return x;
        })
      },
      erro => {
        console.log(erro);
        this.lojas = [];
      }
    )
  }

  selecionaLojaOrigem(item) {
    this.busca.localOrigem = item;
    this.busca.localDevolucao = JSON.parse(JSON.stringify(this.busca.localOrigem));
  }

  selecionaLojaDevolucao(item) {
    this.busca.localDevolucao = item;
  }

  buscaLojasDebounce(val: string) {
    clearTimeout(this.timerCidade);
    this.timerCidade = setTimeout(() => {
      this.buscaLojas(val);
    }, 600);
  }

  setDataOrigem($event){
    this.busca.dataDevolucao = moment(this.busca.dataOrigem).add(1, 'days').format('YYYY-MM-DD');
  }

  setDataParaAmanha() {
    this.carregando = true;
    const hoje = new Date();
    const amanha = new Date(hoje.getTime() + (24 * 60 * 60 * 1000)); // Adiciona um dia (em milissegundos)
    this.busca.dataVisitaBetoCarrero = amanha.toString();
  }

  formatarDataParaInputDate(data: Date): string {
    return data.toISOString().split('T')[0];
  }

  setDataDevolucao($event) {
    if (moment(this.busca.dataOrigem) > moment(this.busca.dataDevolucao)){
      this.busca.dataOrigem = moment(this.busca.dataOrigem).subtract(1, 'days').format('YYYY-MM-DD');
    }
  }

  setaDataBeto(event, dataPrevista) {
    this.busca.dataVisitaBetoCarrero = event;

  }

}
