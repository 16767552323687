<div class="card mb-3 all">
    <div class="card-body row">
        <div class="col-12 col-md-3 d-flex justify-content-center">
            <img style="object-fit: contain; object-position: center; width: 100%;" src="{{carro.Imagem}}" alt="">
        </div>
        <div class="col-12 col-md-6">
            <h4 class="p-0 m-0">{{carro.Descricao}}</h4>
            <p class="">{{carro.ModeloNome}}</p>
            <p class="text-justify">
                <strong>{{'Ocupantes'}}:</strong> {{carro.Pax}}<br>
                <strong>Núm. {{'portas'}}:</strong> {{carro.Portas}}<br>
                <strong>{{'Bagagem'}}:</strong> {{carro.Bagagem}}<br>
                <strong>{{'Ar Condicionado'}}:</strong> {{carro.TemAirCondicionado?"Sim":"Não"}}<br>
                <strong>{{'Transmissão'}}:</strong> {{carro.TransmissaoTipo}}<br>
                <span class="text-justify" *ngFor="let protecao of protecaoSelecionada; let i = index">
                    <strong>Proteção: </strong> {{protecao.Titulo}}<br>
                </span>

            </p>
        </div>

        <div class="col-12 col-md-3">
            <div class="h-100 d-flex flex-column align-items-center justify-content-center">
                <div class='row text-center'>
                    <div class='col d-flex flex-column justify-content-center align-items-center text-center as-c'>
                        <h6>{{"Fornecedor" | translate}}</h6>
                        <img src="assets/img/ciascarros/{{ carro.Fornecedor }}.png" alt="{{ carro.Fornecedor }}"
                            title="{{ carro.Fornecedor }}" />
                    </div>
                </div>

                <div class='row mt-2'>
                    <div class='col d-flex flex-column justify-content-center align-items-center text-center '>
                        <h5> {{carro.MoedaListagem.Sigla}} {{carro.ValorListagem | currency:'BRL':'':'2.2-2' }}</h5>
                        <p>+ {{'Taxas' | translate}}: {{carro.MoedaListagem.Sigla}} {{carro.TaxaAdm +
                            carro.TaxaAdmProtecoes + carro.TaxaExtra | currency :'BRL':'':'1.2-2'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>