<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Cancelamento de aéreo</h4>
</div>
<div class="modal-body">
  <div class="progress">
    <div class="progress-bar bg-success" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">Dados</div>
    <div class="progress-bar" [ngClass]="[this.progress > 1 ? 'bg-success' : 'bg-secondary']" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">Cancelamento</div>
  </div>

  <div id="dados" class="content" *ngIf="progress == 1">
    <h3 class="title">{{"Verifique os dados antes de prosseguir!" | translate}}</h3>
    <div class="row">
      <div class="col">
        <table class="table">
          <thead>
            <tr>
              <th>{{"Tipo" | translate}}</th>
              <th>{{"Qtde" | translate}}</th>
              <th>{{"Tarifa(un)" | translate}}</th>
              <th>{{"Taxas de serviço(un)" | translate}}</th>
              <th>{{"Taxas(un)" | translate}}</th>
              <th>{{"Subtotal" | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tarifa of aereo.PaxesTarifa">
              <td>
                {{(tarifa.TipoPax == 0 ? 'Adulto' : (tarifa.TipoPax == 1 ? 'Criança' : 'Bebê')) | translate}}
              </td>
              <td>{{tarifa.QtdPax}}</td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTarifaListagem | currency:'BRL':'':'1.2-2' }}
              </td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTaxaServicoListagem | currency:'BRL':'':'1.2-2' }}</td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTaxaListagem | currency:'BRL':'':'1.2-2' }}
              </td>
              <td>{{aereo.MoedaListagem.Simbolo}}
                {{tarifa.ValorTarifaListagem + tarifa.TotalTaxasListagem| currency:'BRL':'':'1.2-2' }}
              </td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="aereoRetarifado && (aereoRetarifado.VendaTotal !== aereo.VendaTotal)">
          <h2>Alterou o Valor do Aéreo</h2>
          <p>Valor Antigo: {{aereo.VendaTotal | currency:'BRL':true:'1.2-2' }}</p>
          <p>Valor Atual: {{aereoRetarifado.VendaTotal | currency:'BRL':true:'1.2-2' }}</p>
          <p>
            <label ngbButtonLabel class="btn btn-primary tab-buscador">
              <input type="checkbox" ngbButton [(ngModel)]="cienteAlterouValor"> {{"Estou Ciente" | translate}}
            </label>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div id="cancelamento" class="content" *ngIf="progress == 2">
    <div *ngIf="!carregando && !(aereo.Erros?.length > 0)">
      <h3 class="title">{{"Confirmação" | translate}}</h3>
      <div class="alert alert-success" role="alert">
        {{ "Aéreo Cancelado com Sucesso" | translate }}
      </div>
    </div>
    <div *ngIf="!carregando && (aereo.Erros?.length > 0)">
      <h3 class="title">{{"Não foi possível cancelar" | translate}}</h3>
      <div class="alert alert-warning" role="alert">
        {{"Não foi possível cancelar Aéreo" | translate}}
      </div>
    </div>

    <div *ngIf="carregando">
      <h3 class="title">{{ "Efetivando o cancelamento" | translate}}</h3>
      <app-loading-small></app-loading-small>
    </div>
    <br>
  </div>
</div>
<div class="modal-footer">
  <app-loading-small *ngIf="carregando"></app-loading-small>
  <div class="row">
    <div class='col text-end'>
      <button class="btn btn-success" data-bs-dismiss="modal" (click)="fechou.emit()">{{ progress == 1 ? "Fechar" : "Fechar" | translate }}</button>
      <button class="btn btn-outline-danger" *ngIf="progress == 1" (click)="cancelarAereo()">{{ "Cancelar" | translate}}</button>
    </div>
  </div>

  <div class="row" *ngIf="mensagensErro?.length > 0">
    <div class="alert alert-danger">
      <ul>
        <li *ngFor="let mensagem of mensagensErro">{{mensagem}}</li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="mensagemSucesso?.length > 0">
    <div class="alert alert-success">
      {{mensagemSucesso}}
    </div>
  </div>
</div>
