<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{"Câmbios" | translate}}</h4>
</div>

<div class="modal-body" *ngIf="carregando">
  <app-view-loading></app-view-loading>
</div>


<div class="modal-body" *ngIf="!carregando">
      <div class="strpied-tabled-with-hover">
        <div class="card-header _pb-20">
          <!-- <h4 class="card-title">Agências</h4> -->
          <div class="row">
            <div *ngIf="mensagemSuccesso != ''" class="alert alert-success alert-dismissible show col col-md-12" role="alert">
              <strong> {{ mensagemSuccesso}} </strong>
              <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close" (click)="mensagemSuccesso = ''">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div *ngIf="mensagemErro != ''" class="alert alert-danger alert-dismissible show col col-md-12" role="alert">
              <strong>{{ mensagemErro}}</strong>
              <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close" (click)="mensagemErro = ''">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div class="row">

            <div class="col col-md-3">
              <input type="number" fullWidth fieldSize="medium" shape="rectangle" placeholder="ID" autocomplete="off"
              name="buscarporid" [(ngModel)]="parametroId" (input)="buscarDebounce()" class="form-control"/>
            </div>

            <div class="col col-md-8">
              <input fullWidth fieldSize="medium" shape="rectangle" placeholder="Buscar" autocomplete="off"
              name="buscar" [(ngModel)]="parametroBusca" [disabled]="(parametroId != null && parametroId != '') ? true : false" (input)="buscarDebounce()" class="form-control"/>
            </div>

            <div class="col col-md-1">
              <button [disabled]="parametroBusca.length == 0" (click)="buscarPorParametro()" class="btn btn-success" status="primary" size="small" title="Buscar"  outline ghost><i class="fa fa-search"></i></button>
            </div>
          </div>
        </div>

        <div class="table-full-width table-responsive _pt-20">
          <table class="table table-hover table-striped">
            <thead>
              <th class="text-center">ID</th>
              <th class="text-center">{{"Moeda" | translate}}</th>
              <th class="text-center">{{"Taxa Atual" | translate}}</th>
              <th class="text-center">{{"Ações" | translate}}</th>
            </thead>
            <tbody>
              <tr *ngFor="let dado of dados">
                <td class="text-center">{{dado.Id}}</td>
                <td class="text-center">{{dado.MoedaDestino.Simbolo + ' - ' + dado.MoedaDestino.Nome}}</td>
                <td class="text-center">{{dado.Taxas[0]?.Taxa | currency: 'BRL'}}</td>
                <td class="text-center">
                  <button type="button" class="btn btn-xs botao-log" style="margin-left:5px "title="Log" (click)="log(dado.Id, dado.Alteracoes)">
                    <!-- <log texto="Log" [alteracoes]="dado.Alteracoes" [entidade]="'Cambio'" [chave]="dado.Id"></log> -->
                    Log
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr>

          <div>
            <div class="col col-md-8" style="float:left">
               <p>{{"Ultima atualização" | translate}}: {{moment(dados[0]?.Taxas[0].Alteracoes.CriadoEm).format('DD/MM/YYYY HH:mm:ss')}}<br>
                  {{"Atualizado Por" | translate}}: {{dados[0]?.Taxas[0].Alteracoes.CriadoPor}}</p>
            </div>
          </div>
        </div>
      </div>

</div>
<div class="modal-footer">
  <div class="row">
    <div>
      <button class="btn btn-outline-danger" data-bs-dismiss="modal">{{"Fechar" | translate}}</button>
    </div>
  </div>
</div>
