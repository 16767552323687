import { Component, OnInit, Input, Output, ContentChild, AfterContentInit, EventEmitter, HostListener } from '@angular/core';
import { NgModel, FormControlName  } from '@angular/forms';

@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.css'],
  })
  export class TypeaheadComponent implements OnInit, AfterContentInit  {

    showDropDown = false;
    indice = -1;
    selecionou = false;
    timer = null;
    timeout = 400; // tempo para esperar antes de buscar

    input: any;
    @Input() searchText: any;
    @Input() tap = true;
    @Input() lista: [];
    @Output() searchFunction = new EventEmitter<any>();
    @Output() selectItem = new EventEmitter<any>();

    @Input() notFoundMessage = 'Nenhum item encontrado!';
    @ContentChild(NgModel, { static: true } as any) model: NgModel;
    @ContentChild(FormControlName, { static: true } as any) control: FormControlName;

    constructor() { }

    mobile = false;

    ngOnInit() {

       if (
          navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
        ) {
          this.mobile = true;
       }
    }

    ngAfterContentInit() {
      this.input = this.model || this.control;
      if (this.input === undefined) {
      throw new Error(this + ': Este componente precisa ser usado com ngModel ou FormControlName');
      }

    }

    onSelecionarItem(item: any) {
      this.selecionou = true;
      this.showDropDown = false;
      const copia = JSON.parse(JSON.stringify(item));
      this.selectItem.emit(copia);
      this.lista = [];

    }

    onTap($event) {
      this.showDropDown = true;
      const item = this.lista[this.indice];
      switch ($event.code) {
        case 'Enter':
        if (this.indice >= 0) {
        this.onSelecionarItem(item);
        }

        break;
        case'Escape':
          this.onSelecionarItem({});
          break;
        case'ArrowUp':
          if (this.indice >= 0) {
            this.indice -= 1;
          }
          break;
        case'ArrowDown':
          if (this.indice < this.lista.length - 1) {
            this.indice += 1;
          }
          break;
        case'Tab':
          this.showDropDown = false;
          break;
        case 'KeyA':
        case 'KeyB':
        case 'KeyC':
        case 'KeyD':
        case 'KeyE':
        case 'KeyF':
        case 'KeyG':
        case 'KeyH':
        case 'KeyI':
        case 'KeyJ':
        case 'KeyK':
        case 'KeyL':
        case 'KeyM':
        case 'KeyN':
        case 'KeyO':
        case 'KeyP':
        case 'KeyQ':
        case 'KeyR':
        case 'KeyS':
        case 'KeyT':
        case 'KeyU':
        case 'KeyV':
        case 'KeyX':
        case 'KeyZ':
        case 'KeyW':
        case 'KeyY':
        case 'Space':
        case 'Semicolon':
        case 'Backspace':
        case '':
          if (this.tap) {
              this.search();
          }

          this.selecionou = false;

          break;

        default:

      }

    }


    search() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.searchFunction.emit(this.searchText);
        }, 600);
    }



    toggleDropDown() {
      this.showDropDown = !this.showDropDown;
    }

    @HostListener('keyup', ['$event'])
    onKeyUpChild(event: any) {
      // setTimeout(() => this.onTap(event), 200) ;
      this.onTap(event);
    }


    @HostListener('focusout', ['$event'])
    public onListenerTriggered(event: any): void {


      setTimeout( () => {
        this.showDropDown = false;
        // tslint:disable-next-line: triple-equals
        if (this.indice != -1 && !this.selecionou) {
          this.selectItem.emit(this.lista[this.indice]);
          this.selecionou = true;
        }
      }, 200);
    }

    @HostListener('focusin', ['$event'])
    public onListenerTriggereds(event: any): void {
      event.target.select();
      this.lista = [];
      this.showDropDown = false;
      this.indice = -1;
    }



    @HostListener('document:click', ['$event'])
    onClickOutside($event) {
      try {
        const classes = ($event.target.className.type === 'string') ? $event.target.className.split(' ') : [];
        if (this.showDropDown && classes.indexOf('elementRefItems') === -1) {
          this.onSelecionarItem({Nome: '', AAAA: 'adasdasdasd'});
        }
      } finally {}

    }


  }
