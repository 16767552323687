<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{"Detalhes do Serviço" | translate}}</h5>
        <i type="button" class="btn btn-search" class="fas fa-times" (click)="fecharModal()"></i>
    </div>

    <div class="modal-body">
        <div class="container">
            <div class="tab-content">
                <div id="home" class="tab-pane active">
                    <h5>{{"Descrição Geral" | translate}}</h5>
                    <p [innerHTML]="objServico?.Descricao"></p>
                    
                    <p *ngIf="objServico?.ListDiasSemana">{{"Dias de Operação" | translate}}:
                        <span *ngFor="let dia of diasSemana; let i = index">
                            <ng-container *ngIf="objServico.ListDiasSemana.includes(i)">
                                {{ dia | translate }}<span *ngIf="i !== objServico.ListDiasSemana[objServico.ListDiasSemana.length - 1]">, </span>
                            </ng-container>
                        </span>
                    </p>
                    <p>{{fornecedor}}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <a type="button" class="btn btn-success" data-bs-dismiss="modal"
            (click)="fecharModal()">{{"Voltar" | translate}}</a>
    </div>
</div>
