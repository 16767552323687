export class Carro {
    public Id: number;
    public selecionado: boolean;
    public Descricao: string;
    public Codigo: string;
    public ModeloCodigo: string;
    public ModeloNome: string;
    public Pax: number;
    public Bagagem: number;
    public TemAirCondicionado: boolean;
    public TransmissaoTipo: string;
    public Categoria: string;
    public Portas: number;
    public Imagem: string;
    public Protecoes : any;
    public ValorProtecoes : any;
    public TaxaAdmProtecoes : any;
    public MoedaListagem : any;
}

export class BuscaCarroRQ {
    public LocalOrigem: any;
    public LocalDevolucao: any;
    public DataHoraOrigem: any;
    public DataHoraDevolucao: any;
    public Moeda: string;
    public Guid: string;
    public Rebusca: boolean;
    public AgenciaId: number;
}

export class LojasCarros {
    Id: number;
    Sigla: string;
    Nome: string;
    Endereco: string;
    display_field: string;
}

export class FiltroCarro {
}
