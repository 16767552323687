<div class="bs-example">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <a href="#" class="navbar-brand">
      <img [src]="logo" class="mmc-logo" style="max-height: 18vh !important">
      <img [src]="logolight" class="mmc-logo-light">
    </a>
    <i class="fas fa-bars" data-bs-toggle="collapse" data-bs-target=".collapse"></i>

    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="nav-item" id="hello-user">
        <h6>{{'Olá' | translate}} {{ usuarioNome }},</h6>
      </div>
      <div class="nav-item" [ngClass]="activeNav.home ? 'active' : ''">
        <a routerLink="/" class="nav-link ">{{'Início' | translate}}</a>
      </div>
      <div class="nav-item" [ngClass]="activeNav.orcamento ? 'active' : ''">
        <a routerLink="/meus-orcamentos" class="nav-link ">{{'Meus Orçamentos' | translate}}</a>
      </div>

      <div class="nav-item" *ngIf="USA_BETO" [ngClass]="activeNav.beto ? 'active' : ''">
        <a routerLink="/beto-carrero" class="nav-link ">{{'Beto Carrero' | translate}}</a>
      </div>
      <div class="nav-item" [ngClass]="activeNav.pacotes ? 'active' : ''" *ngIf="linkpacote != ''">
        <a routerLink="/listagem-pacotes/pacotes" class="nav-link ">{{'Pacotes' | translate}}</a>
      </div>
      <div class="nav-item" *ngIf="linkportfolio != ''">
        <a [href]="linkportfolio" target="_blank" class="nav-link ">{{'Portfólio' | translate}}</a>
      </div>
      <div class="nav-item " *ngIf="linktarifario != ''">
        <a [href]="linktarifario" target="_blank" class="nav-link ">{{'Tarifário' | translate}}</a>
      </div>
      <div class="nav-item " *ngIf="linkblackfriday != ''">
        <a [href]="linkblackfriday" target="_blank" class="nav-link ">{{'Week Off 2024' | translate}}</a>
      </div>
      <div class="nav-item" id="sair">
        <button class="nav-link" (click)="logout()">
          Sair <i class="fas fa-sign-out-alt" style="color: white"></i>
        </button>
      </div>
    </div>

  </nav>
</div>
