import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Busca, Pacote, Paginacao, Quarto } from '../../../model';
import { PacotelstService } from '../../../services/turismo/pacotelst.service';
import { LoginService, Util } from '../../../services';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { BACKGROUNDIMAGE,IDIOMA } from 'src/app/app.config';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-pacotes',
  templateUrl: './pacotes.component.html',
  styleUrls: ['./pacotes.component.scss']
})

export class PacotesComponent implements OnInit {
  @Output() pacote;

  @Input() Orcamento: Pacote[] = [];
  @Input() events: Observable<void>;
  @Input() busca: Busca;

  @Output() selecionou = new EventEmitter<any>();
  @Output() selecionouPacote = new EventEmitter<any>();
  @Output() avancar = new EventEmitter<any>();
  @Output() removeu = new EventEmitter<any>();

  private eventsSubscription: Subscription;
  backgroundimage = BACKGROUNDIMAGE;
  paginacao: Paginacao = new Paginacao();
  quarto: Quarto = new Quarto();
  Pacote = new Pacote();
  idioma = IDIOMA;
  PacoteDetalhes: any;

  // pacotesLst = [];

  dataAtual: any;

  // noitesAdd = false;
  // agenciaSelecionada: boolean;
  // adicionar = false;
  // id = null;

  desabilitaTarifa = true;
  semAgencia = false;
  AgenciaId: number;
  Destinos = [];
  Pacotes: Pacote[] = [];
  DestinoId: number = 0;
  datasvalidas = [];
  usaMinNoites = false;
  erros = [];
  mensagemSucesso = '';
  usuarioLogado = null;
  loading: boolean = false;
  minDate:any;
  maxDate:any;
  // parametroBusca: string = '';
  // mensagemAlert = 'Selecione uma agência!';

  constructor(
    public router: Router,
    private pacotelstservice: PacotelstService,
    private loginService: LoginService,
    private _router: Router,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    private utilService: UtilsService

  ) { }

  ngOnInit() {
    window.sessionStorage.removeItem('buscapacote');
    this.buscarPacoteDestino(0);
    this.buscarDestinos();
    this.carregaAgencia();
    this.busca.trechos[0].minDataIda = moment().add(1, 'days');
    this.usuarioLogado = this.loginService.user();
    this.busca.usuario = this.usuarioLogado;
  }

  buscarPacoteDestino(destinoId) {
    this.loading = true;
    this.erros = [];
    this.mensagemSucesso = '';
    let busca = this.pacotelstservice.buscaPacotesDestinos(destinoId, this.paginacao.NumeroPagina);
    busca.subscribe(
      dados => {
        this.loading = false;
        this.Pacotes = dados.Items;
        this.Pacotes.forEach(p => {
          p.PacoteImagens.forEach(pi => {
            pi.Imagem = Util.pathImagemApi(pi.Imagem);
          });
          p.PacotePeriodos.forEach(pp => {
            pp.CheckinDe = moment(pp.CheckinDe).format("YYYY-MM-DD");
            pp.CheckoutAte = moment(pp.CheckoutAte).format("YYYY-MM-DD");
          });
          p.PacotePeriodos.sort(function (a, b) {
            return a.CheckinDe - b.CheckinDe;
          });
        });

        this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
        this.paginacao.TotalItens = dados.MetaData.TotalItens;
        this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
        this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
      },
      erro => {
        this.loading = false;
        this.Pacotes = [];
        this.erros.push(this.errorHandler(erro, 'Buscar Pacotes'));
      },
    );
  }

  BuscaPacotes() {
    this.semAgencia = false;
    this.busca.primeira = true;
    this.busca.novoOrcamento = true;
    this.busca.orcamentoId = null;
    this.busca.PacoteId = this.Pacote.Id;
    if (this.Pacote.BloqueioAereo.length > 0) {
      this.busca.trechos[0].AeroportoPartida = this.Pacote.BloqueioAereo[0].AeroportoPartida;
      this.busca.trechos[0].AeroportoChegada = this.Pacote.BloqueioAereo[0].AeroportoChegada;
    }else{
      this.busca.tipoBusca.aereo = false;
    }
    this.busca.trechos[0].cidadeDestino.Id = this.Pacote.Cidade.Id;
    this.busca.trechos[0].cidadeDestino.Nome = this.Pacote.Cidade.Nome;

    if (this.usuarioLogado.AgenciaId != null) {
      this.busca.AgenciaId = this.usuarioLogado.AgenciaId;
    } else {
      let agencia = window.localStorage.getItem("g8h7d8");
      if (agencia !== null) {
        const agenciaID = JSON.parse(atob(agencia));
        this.busca.AgenciaId = agenciaID.Id;
      } else {
        alert("Selecione uma agência!");
        // alert(this.utilService.fazerTraducao(this.mensagemAlert));
      }
    }
    if (!this.busca.AgenciaId || this.busca.AgenciaId == 0) {
      this.semAgencia = true;
      setTimeout(() => (this.semAgencia = false), 5000);
      return null;
    }

    sessionStorage.removeItem('buscapacote');
    sessionStorage.setItem('buscapacote', JSON.stringify(this.busca));

    this.modalService.dismissAll();
    this._router.navigate([`listagem-pacotes/pacote-hotel`]);
  }

  buscarPagina(pagina) {
    this.paginacao.NumeroPagina = pagina;
    this.buscarPacoteDestino(0);
  }


  abrirModal(obj, content) {
    this.desabilitaTarifa = true;
    this.usaMinNoites = false;
    this.Pacote = obj;
    this.busca = new Busca();
    this.busca.tipoBusca.hotel = true;
    this.busca.usuario = this.usuarioLogado;
    this.datasvalidas = [];
    let primeiradata = true;
    if(obj.PacotePeriodos.length > 0){
      obj.PacotePeriodos.forEach(pp => {
        this.datasvalidas.push(moment(pp.CheckinDe).format("YYYY-MM-DD"));
      });
      if(primeiradata){
        this.busca.trechos[0].dataIda = moment(obj.PacotePeriodos[0].CheckinDe).format("YYYY-MM-DD");
        this.setDataIda(this.busca.trechos[0].dataIda, this.busca.trechos, 0);
      }

    } else {
      if (moment(obj.Checkin) <  moment(this.busca.trechos[0].minDataIda)) {
        this.busca.trechos[0].dataIda = moment(this.busca.trechos[0].minDataIda).format("YYYY-MM-DD");
        this.minDate = moment(this.busca.trechos[0].minDataIda).format("YYYY-MM-DD");
      } else {
        this.minDate = moment(obj.Checkin).format("YYYY-MM-DD");
      }
      this.maxDate = moment(obj.Checkout).format("YYYY-MM-DD");
      this.usaMinNoites = true;
      this.busca.trechos[0].dataIda = this.minDate;
      this.busca.trechos[0].dataVolta = this.maxDate;
      this.setDataIda(this.minDate, this.busca.trechos, 0);
    }
    sessionStorage.clear();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
  }

  fecharModal() {
  this.modalService.dismissAll();
  }

  incrementaQuartos() {
    this.busca.quartos.push(new Quarto());
  }

  decrementaQuartos() {
    this.busca.quartos.pop();
  }

  carregaAgencia() {
    if (this.AgenciaId == 0) {
      let agencia = window.localStorage.getItem("g8h7d8");
      if (agencia != null) {
        let a = JSON.parse(atob(agencia));
        this.AgenciaId = a.Id;
      }
    }
  }

  setDataIda(event: string, trechos, indice) {
    this.desabilitaTarifa = false;
    let periodo = this.Pacote.PacotePeriodos.filter(x => x.CheckinDe == event);
    if (this.usaMinNoites) {
      trechos[indice].dataVolta = moment(event).add(
        this.Pacote.MinimoDeNoites, 'days'
      ).format("YYYY-MM-DD")
    } else {
      trechos[indice].dataVolta = moment(periodo[0].CheckoutAte).format("YYYY-MM-DD");
    }
    this.GetDateDiffIda(event, trechos, indice);
  }

  GetDateDiffIda(event, trechos, indice) {
    let data1 = moment(event);
    let data2 = moment(trechos[indice].dataVolta)
    let diffdays = moment(data2).diff(data1, 'day');
    this.busca.nrnoites = diffdays;
  }

  onChangeVolta(event, trechos, indice) {
    this.GetDateDiffVolta(event, trechos, indice);
  }

  GetDateDiffVolta(event, trechos, indice) {
    if (trechos[indice].dataVolta != null) {
      event = moment(event);
      const diffdays = event.diff(trechos[indice].dataIda, 'days');
      this.busca.nrnoites = diffdays;
    }
  }

  buscarDestinos() {
    this.pacotelstservice.listDestinos().subscribe(
      dados => {
        this.Destinos = dados;
      },
      erro => {
        console.log(erro);
      },
    );
  }

  public errorHandler(erro, acao: string = null): string {
    let mensagem = 'Erro';
    if (acao !== null) {
      mensagem += ' ao tentar ' + acao + ': ';
    }
    switch (erro.status) {
      case 0: mensagem += 'o servidor não respondeu, tente novamente mais tarde!'; break;
      case 401: mensagem += 'você não tem autorização para executar esta ação!'; break;
      case 404: mensagem += erro.error.mensagem; break;
      case 406: mensagem += 'a requisição não foi aceita, tente novamente!'; break;
      case 500: mensagem += 'Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'; break;
      default: mensagem += erro.statusText;
    }
    return mensagem;
  }

}


  // refazerBusca(busca) {
  //   this.busca = busca;
  //   this.paginacao.NumeroPagina = 1;
  //   this.buscarDestino(0);
  // }

  // noitesAdicionais() {
  //   this.noitesAdd = !this.noitesAdd;
  // }

  // selecionarPacote(pacote) {
  //   pacote.selecionado = true;
  //   this.Orcamento.push(pacote);
  //   this.selecionou.emit(pacote);
  //   this.BuscaPacotes();
  // }


  // pacoteSelecionado(pacote) {
  //   const dado = this.Orcamento.filter(x => x.Id === pacote.Id);
  //   return dado.length > 0;
  // }

  // removerPacote(pacote) {
  //   pacote.selecionado = false;
  //   let indice = -1;
  //   for (let i = 0; i < this.Orcamento.length; i++) {
  //     if (this.Orcamento[i].Id === pacote.Id) {
  //       indice = i;
  //     }
  //   }
  //   if (indice >= 0) {
  //     this.Orcamento.splice(indice, 1);
  //     this.removeu.emit(pacote);
  //   }
  // }
