import { Component, Input, OnInit } from '@angular/core';
import { BetoCarrero } from 'src/app/model/turismo/beto-carrero.model';

@Component({
  selector: 'app-orcamento-beto-card',
  templateUrl: './orcamento-beto-card.component.html',
  styleUrls: ['./orcamento-beto-card.component.scss']
})
export class OrcamentoBetoCardComponent implements OnInit {

  @Input() index: number;
  @Input() beto: BetoCarrero;
  constructor() { }

  ngOnInit(): void {
  }

}
