<div class="revisao">
  <div class="hotel-card">
    <div class="image">
      <div class="row space-evenly">
        <div class="col hotel-cover">
          <img class="card-img-top" src="{{hotel.HotelImagem != null && hotel.HotelImagem != '' ? hotel.HotelImagem: backgroundimage}}" alt="hotel image"/>
        </div>
      </div>
    </div>
    <div class="details p-2">
      <div class="row mt-1">
        <div class="col">
          <h4>{{ hotel.Nome  }}</h4>
        </div>
      </div>
      <div class="row mb-2">
        <div class="stars">
          <app-estrelas [estrela]="hotel.Estrelas"></app-estrelas>
        </div>
      </div>
      <div class='row'>
        <div class='col'>
            <h6>{{"Tipo de Quarto" | translate }}: {{hotel.Quartos[0].Descricao}} </h6>
        </div>
      </div>
      <div class="row mb">
        <div class="col">
          <p>{{ hotel.Endereco }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div *ngIf="Pacote == false && orcamentoId" class="col">
            {{"Selecione a Opção Desejada" | translate}}:<br>
            <select class="form-select" name="opcaoHotel" [(ngModel)]="hotel.Opcao">
              <option [value]="1">1</option>
              <option [value]="2">2</option>
              <option [value]="3">3</option>
              <option [value]="4">4</option>
              <option [value]="5">5</option>
              <option [value]="6">6</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="preco" *ngIf="!pacotetirapreco">
      <div class="row text-center">
        <div *ngIf="Orcamento?.PacoteReservas" class="col">{{"A partir de" | translate }}:</div>
        <div *ngIf="!Orcamento?.PacoteReservas" class="col">{{"Valor do pacote" | translate }}:</div>
      </div>

      <div class="row text-center mb">
        <div class="col">
          <h4>{{hotel.Quartos[0].MoedaListagem.Simbolo}} {{ somaTotal(hotel.Quartos)+ValorServico | currency:'BRL':'':'2.2-2' }}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
