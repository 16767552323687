<div class="beto-card">
    <div class="image">
      <div class="row space-evenly">
        <div class="col beto-cover">
          <img class="card-img-top"
            src="data:image/png;base64,{{beto.Imagem}}" />
        </div>
      </div>
    </div>
  
    <div class="card-body">
      <div>
            <h4 class="fonte-cabecalho-card"> {{beto.Titulo}}
            </h4>
            <div>
              <strong>{{'Data escolhida' | translate}}:</strong> {{beto.DataEscolhida| date : 'dd/MM/yyyy'}}
            </div>
            <div id="modalLink" data-bs-toggle="modal" data-bs-target="#detailsModal">
              <button class="btn btn-success">{{"Detalhes do Serviço" | translate }}</button>
            </div>
      </div>
    </div>
  
    <div class="preco">
      <div class="row text-center">
        <div class="col">
          {{"A partir de" | translate}}:
        </div>
      </div>
  
      <div class="row text-center">
        <div class="col">
          <h4>{{beto.MoedaListagem.Simbolo}} {{ beto.ValorListagem | currency:'BRL':'':'2.2-2' }}</h4>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Service Details Modal -->
  <div class="modal fade" tabindex="-1" id="detailsModal" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{"Detalhes do Ingresso" | translate }}</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="tab-content">
              <div id="home" class="tab-pane active">
                <h5>{{"Tipo do Ingresso" | translate}}</h5>
                <p>
                  {{ beto.DescricaoTipoProduto }}
                </p>
                <h5>{{"Descrição Geral" | translate}}</h5>
                <p [innerHTML]="beto.DescricaoCliente"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a type="button" class="btn btn-success" data-bs-dismiss="modal">{{"Voltar" | translate}}</a>
        </div>
      </div>
    </div>
  </div>
  
  