import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BetoCarreroService } from "src/app/services/turismo/beto-carrero.service";
import { LoginService } from '../../../services';
import { BetoCarrero } from '../../../model/turismo/beto-carrero.model';
import { Usuario } from './../../../model/usuario.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import { __read, __spreadArrays } from 'tslib';
@Component({
  selector: 'beto-cancela',
  templateUrl: './beto-cancela.component.html',
  styleUrls: ['./beto-cancela.component.scss']
})
export class BetoCancelaComponent implements OnInit {

  @Input() Ingresso: BetoCarrero;
  @Output() alterou = new EventEmitter<any>();

  carregando: boolean = false;

  mensagemSucesso: string;
  mensagensErro: string[] = [];

  constructor(
    private betoCarreroService: BetoCarreroService,
    private modalService: NgbModal
  ) { }

  ngOnInit() { }

  fecharModal() {
    this.modalService.dismissAll();
  }

  cancelar() {
    this.mensagensErro = [];
    this.mensagemSucesso = '';
    this.carregando = true;

    this.betoCarreroService.cancelarReservaIngresso(this.Ingresso.Id).subscribe(
      dados => {
        this.carregando = false;
        if (dados.Erros && dados.Erros.length > 0) {
          this.mensagensErro = dados.Erros;
          return;
        }
        this.Ingresso = dados.Ingresso;

        this.alterou.emit(this.Ingresso);

        this.mensagemSucesso = `Ingresso ${this.Ingresso.Titulo} cancelado com sucesso`;
      }, erro => {
        this.carregando = false;
        this.mensagensErro = this.errorHandler(erro);
        erro.error.dados.Erros.forEach(erro => this.mensagensErro.push(erro));
        console.log(erro);
      }
    );
  }

  public errorHandler(erro): string[] {

    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400:

        if (erro.error && erro.error.mensagem)
          mensagem.push(erro.error.mensagem);
        else
          mensagem.push("Houve algum erro na execução, tente novamente!");

        break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push(erro.error.mensagem); break;
      case 406: mensagem.push(erro.error.mensagem); break;
      // case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ); break;
      case 500: mensagem.push(erro.error.ExceptionMessage); break;
      default: mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      mensagem.push("Houve algum erro na execução, tente novamente!");
    }
    return mensagem;

  }

}
