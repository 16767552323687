// tslint:disable: max-line-length
// COMPONENTES
import { SelecionarConsultorComponent } from './components/reserva/selecionar-consultor/selecionar-consultor.component';
import { RecuperarSenhaComponent } from './components/recuperar-senha/recuperar-senha.component';
import { PacotesComponent } from './views/lista-pacotes/pacotes/pacotes.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ListagemComponent } from './views/listagem/listagem.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent} from './shared/header/header.component';
import { HomeComponent } from './views/home/home.component';
import { BrowserModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgModule, LOCALE_ID } from '@angular/core';
import { routing } from './app.routing.modules';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { OrcamentodownloadComponent } from './orcamentodownload/orcamentodownload.component';
import { AlterarsenhaComponent } from './components/alterarsenha/alterarsenha.component';

// LISTAS
import { OrcamentoServicoCardComponent } from './views/listagem/orcamento/orcamento-servico-card/orcamento-servico-card.component';
import { OrcamentoPacoteCardComponent } from './views/listagem/orcamento/orcamento-pacote-card/orcamento-pacote-card.component';
import { OrcamentoHotelCardComponent } from './views/listagem/orcamento/orcamento-hotel-card/orcamento-hotel-card.component';
import { OrcamentoAereoCardComponent } from './views/listagem/orcamento/orcamento-aereo-card/orcamento-aereo-card.component';
import { RevisaOrcamentoComponent } from './views/listagem/revisa-orcamento/revisa-orcamento.component';
import { ListaServicoComponent } from './views/listagem/lista-servico/lista-servico.component';
import { ServicoComponent } from './views/listagem/lista-servico/servico/servico.component';
import { ListaHotelComponent } from './views/listagem/lista-hotel/lista-hotel.component';
import { ListaAereoComponent } from './views/listagem/lista-aereo/lista-aereo.component';
import { AereoComponent } from './views/listagem/lista-aereo/aereo/aereo.component';
import { HotelComponent } from './views/listagem/lista-hotel/hotel/hotel.component';

// VIEWS
import { MeusOrcamentosComponent } from './views/meus-orcamentos/meus-orcamentos.component';
import { OrcamentoComponent } from './views/orcamento/orcamento.component';

// SERVICES AND UTILS
import {
  LoginService, AuthInterceptor, LoaderInterceptor, LoaderService, CidadeService, AgenciaService, AeroportoService,
  ReservaService, AereoService, HotelService, ServicoService, LogService, UsuarioService, VoucherService,
  EmpresaService, PerfilMarkupService, WsService, FormaPagamentoService, CambioService, SeguroService,
  ParametroService, ProfissaoService, PaisService
} from './services';
import { CarroService } from './services/turismo/carro.service';

import { AgenciaSelecionarComponent } from './components/agencia-selecionar/agencia-selecionar.component';
import { ReservaServicoComponent } from './components/reserva-servico/reserva-servico.component';
import { TrechoSimbolComponent } from './shared/trecho-simbol/trecho-simbol.component';
import { AreaTesteComponent } from './views/area-teste/area-teste.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TypeaheadComponent } from './shared/typeahead/typeahead.component';
import { PaginacaoComponent } from './shared/paginacao/paginacao.component';
import { BuscaComponent } from './shared/busca/busca.component';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import localePt from '@angular/common/locales/pt';

// MODELS
import { Agencia, Busca, BuscaAereoRQ } from './model';

// RESERVA - AEREO
import { AereoCancelaComponent } from './components/reserva/aereo-cancela/aereo-cancela.component';
import { AereoReservaComponent } from './components/reserva/aereo-reserva/aereo-reserva.component';
import { AereoEmiteComponent } from './components/reserva/aereo-emite/aereo-emite.component';
import { InputComponent } from './shared/input/input.component';

// RESERVA - HOTEL
import { HotelReservaComponent } from './components/reserva/hotel-reserva/hotel-reserva.component';

// RESERVA - SERVICO
import { ServicoCancelaComponent } from './components/reserva/servico-cancela/servico-cancela.component';
import { ServicoReservaComponent } from './components/reserva/servico-reserva/servico-reserva.component';

import { ComprovanteCartaoComponent } from './components/reserva/comprovante-cartao/comprovante-cartao.component';
import { EstornoPagamentoComponent } from './components/reserva/estorno-pagamento/estorno-pagamento.component';
import { ReservaPagamentosComponent } from './components/reserva-pagamentos/reserva-pagamentos.component';
import { PacoteHotelComponent } from './views/lista-pacotes/pacote-hotel/pacote-hotel.component';
import { HotelDetailsComponent } from './components/hotel-details/hotel-details.component';
import { LoadingSmallComponent } from './shared/loading-small/loading-small.component';
import { ListaPacotesComponent } from './views/lista-pacotes/lista-pacotes.component';
import { ViewLoadingComponent } from './shared/view-loading/view-loading.component';
import { CambioComponent } from './components/cambio/cambio.component';
import { RebuscaComponent } from './views/rebusca/rebusca.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EstrelasComponent } from './shared/estrelas/estrelas.component';
import { VoucherComponent } from './voucher/voucher.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HotelDetalhesComponent } from './views/listagem/lista-hotel/hotel-detalhes/hotel-detalhes.component';
import { TagInputModule } from 'ngx-chips';
import { PacoteServicoComponent } from './views/lista-pacotes/pacote-servico/pacote-servico.component';
import { PreOrcamentoComponent } from './views/lista-pacotes/pre-orcamento/pre-orcamento.component';
import { HotelQuartosComponent } from './views/listagem/lista-hotel/hotel-quartos/hotel-quartos.component';
import { ServicoDetalhesComponent } from './views/listagem/lista-servico/servico-detalhes/servico-detalhes.component';
import { ReservaPacoteComponent } from './components/reserva/reserva-pacote/reserva-pacote.component';
import { CancelaPacoteComponent } from './components/reserva/cancela-pacote/cancela-pacote.component';
import { LaminasComponent } from './components/laminas/laminas.component';
import { LaminasService } from './services/laminas.service';
import { LogComponent } from './components/log/log.component';
import { BloqueioComponent } from './views/listagem/lista-aereo/bloqueio/bloqueio.component';
import { ListaCarroComponent } from './views/listagem/lista-carro/lista-carro.component';
import { CarroComponent } from './views/listagem/lista-carro/carro/carro.component';
import { OrcamentoCarroCardComponent } from './views/listagem/orcamento/orcamento-carro-card/orcamento-carro-card.component';
import { CarroReservaComponent } from './components/reserva/carro-reserva/carro-reserva.component';
import { CarroCancelaComponent } from './components/reserva/carro-cancela/carro-cancela.component';
import { ListaBetoCarreroComponent } from './views/lista-beto-carrero/lista-beto-carrero.component';
import { BetoCarreroComponent } from './views/lista-beto-carrero/beto-carrero/beto-carrero.component';
import { BetoCarreroPreOrcamentoComponent } from './views/lista-beto-carrero/beto-carrero-pre-orcamento/beto-carrero-pre-orcamento.component';
import { BetoCarreroService } from './services/turismo/beto-carrero.service';
import { BetoReservaComponent } from './components/reserva/beto-reserva/beto-reserva.component';
import { BetoCancelaComponent } from './components/reserva/beto-cancela/beto-cancela.component';
import { OrcamentoBetoCardComponent } from './views/listagem/orcamento/orcamento-beto-card/orcamento-beto-card.component';

// const routes: Routes = [
//   { path: '', component: HomeComponent }
// ];

registerLocaleData(localePt);
TagInputModule.withDefaults({
  tagInput: {
    placeholder: 'Adicionar',
    // add here other default values for tag-input
  },
  dropdown: {
    displayBy: 'my-display-value',
    // add here other default values for tag-input-dropdown
  },
});
@NgModule({
  declarations: [
    AppComponent,
    OrcamentodownloadComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    HeaderComponent,
    PacotesComponent,
    HotelComponent,
    AlterarsenhaComponent,
    ListaServicoComponent,
    ServicoComponent,
    RevisaOrcamentoComponent,
    OrcamentoHotelCardComponent,
    OrcamentoServicoCardComponent,
    MeusOrcamentosComponent,
    PacoteHotelComponent,
    OrcamentoComponent,
    ListaHotelComponent,
    ListaAereoComponent,
    AgenciaSelecionarComponent,
    AereoComponent,
    AreaTesteComponent,
    TrechoSimbolComponent,
    OrcamentoAereoCardComponent,
    TypeaheadComponent,
    BuscaComponent,
    PaginacaoComponent,
    ListagemComponent,
    ReservaServicoComponent,
    HotelReservaComponent,
    SelecionarConsultorComponent,
    InputComponent,
    AereoCancelaComponent,
    AereoEmiteComponent,
    AereoReservaComponent,
    HotelReservaComponent,
    ServicoReservaComponent,
    ServicoCancelaComponent,
    EstornoPagamentoComponent,
    ComprovanteCartaoComponent,
    ReservaPagamentosComponent,
    LoadingSmallComponent,
    ViewLoadingComponent,
    CambioComponent,
    RebuscaComponent,
    HotelDetailsComponent,
    RecuperarSenhaComponent,
    ListaPacotesComponent,
    OrcamentoPacoteCardComponent,
    EstrelasComponent,
    VoucherComponent,
    LoaderComponent,
    HotelDetalhesComponent,
    PacoteServicoComponent,
    PreOrcamentoComponent,
    HotelQuartosComponent,
    ServicoDetalhesComponent,
    ReservaPacoteComponent,
    CancelaPacoteComponent,
    LaminasComponent,
    LogComponent,
    BloqueioComponent,
    ListaCarroComponent,
    CarroComponent,
    OrcamentoCarroCardComponent,
    CarroReservaComponent,
    CarroCancelaComponent,
    ListaBetoCarreroComponent,
    BetoCarreroComponent,
    BetoCarreroPreOrcamentoComponent,
    BetoReservaComponent,
    BetoCancelaComponent,
    OrcamentoBetoCardComponent,
  ],
  imports: [
    BrowserModule,
    routing,
    FormsModule,
    HttpClientModule,
    Ng5SliderModule,
    NgxSliderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(),
    PdfViewerModule,
    TagInputModule,
  ],
  providers: [
    LoginService,
    ParametroService,
    LoaderService,
    CidadeService,
    AgenciaService,
    AeroportoService,
    ReservaService,
    AereoService,
    HotelService,
    ServicoService,
    SeguroService,
    LogService,
    UsuarioService,
    VoucherService,
    EmpresaService,
    PerfilMarkupService,
    WsService,
    FormaPagamentoService,
    PaisService,
    ProfissaoService,
    CambioService,
    LaminasService,
    CarroService,
    BetoCarreroService,
    Agencia,
    Busca,
    BuscaAereoRQ,
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
