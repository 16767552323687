<app-view-loading *ngIf="buscando"></app-view-loading>
<div class="pagebg" *ngIf="!buscando">
  <div class="banner-image" [ngStyle]="{'background-image': 'url(' + pacoteReserva.PacoteImagens[0]?.Imagem + ')'}">
    <div class="banner-text">
      <h2>PACOTE - {{ pacoteReserva.Nome }}</h2>
    </div>
  </div>
  <div class="container">
    <div class='row mt-3'>
      <div class='container col-md-12'>
        <h4 class="col-md-5">{{"Dia a dia" | translate }}</h4>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <!-- <h3 class="card-title text-center cor-primaria mb-4">{{pacoteReserva.Nome}}</h3> -->
        <div class="p-2">
          <ul class="nav nav-tabs" id="tabInfoServico" role="tablist">
            <li class="nav-item" role="presentation" *ngFor="let inclui of pacoteReserva?.Pacote?.PacotesInclui; let x = index">
              <button class="nav-link" [ngClass]="x == 0 ? 'active' : ''" id="tabservico{{x}}" data-bs-toggle="tab" [attr.data-bs-target]="'#tabservicox' + x" type="button" style="text-decoration: none !important;" role="tab" [attr.aria-controls]="'tabservicox' + x" [attr.aria-selected]="x == 0 ? true : false">{{inclui?.Titulo}}
              </button>
            </li>
          </ul>
          <div class="tab-content p-2 mt-2" id="tabInfoServicoContent">
            <div *ngFor="let inclui of pacoteReserva?.Pacote?.PacotesInclui; let x = index" class="tab-pane fade" id="tabservicox{{x}}" [ngClass]="x == 0 ? 'show active' : ''" role="tabpanel" [attr.aria-labelledby]="'tabservico' + x" [innerHTML]="inclui?.Descritivo">
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="pacoteReserva.Aereos != null">
       <div class='container col-md-12 mb-3 mt-3'>
        <h4 class="col-md-5">{{"Aéreo(s) Incluído(s)"}}</h4>
      </div>
      <div *ngFor="let aereo of pacoteReserva.Aereos">
        <div class="all aereo-card">
          <div class="bodyContent">
            <div class="seguimentosGrouperNormal">
              <div *ngFor="let seguimento of aereo.Seguimentos; let i = index" class="seguimento">
                <div class="ida" *ngIf="i % 2 == 0">

                  <div *ngFor="let voo of seguimento.Voos; let k = index">
                    <div class='row jc-se'>
                      <div class='col-2 as-c'>
                        <img src="assets/img/cias/{{voo.CiaMarketing}}.png" alt="{{voo.CiaMarketing}}" title="{{aereo.CiaValidadora}}" />
                      </div>
                      <div class='col-2'>
                        <p *ngIf="k == 0 || (k > 0 && voo.AeroportoPartida == seguimento.Voos[k - 1].AeroportoChegada)">{{ voo.AeroportoPartida }}</p>
                        <p *ngIf="k > 0 && voo.AeroportoPartida != seguimento.Voos[k - 1].AeroportoChegada" style="color:red">{{ voo.AeroportoPartida }}*</p>
                        <p style="font-size: 13px;font-weight: bold;">{{ voo.DataPartida | date : 'dd/MM/yyyy HH:mm'}}</p>
                      </div>
                      <div class='col-3 text-center'>
                        <div class='col voo-detalhes'>
                          <app-trecho-simbol></app-trecho-simbol>
                        </div>
                      </div>
                      <div class='col-2'>
                        <p>{{ voo.AeroportoChegada }}</p>
                        <p style="font-size: 13px;font-weight: bold;">{{voo.DataChegada | date : 'dd/MM/yyyy HH:mm'}}</p>
                      </div>
                    </div>

                    <div class='row'>
                      <div class='col'>
                        {{"Voo" | translate}} N°{{voo.NumeroVoo}} | {{"Classe" | translate}}: {{voo.ClasseTarifaria}} | {{"Bag." | translate}}: {{seguimento.BagagemQuantidade}} | {{seguimento.FamiliaTarifaria}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="volta" *ngIf="(i % 2 != 0)">
                  <div class='row jc-sb'>
                    <div class='col-2'>
                      <i class="fas fa-plane-arrival"></i>
                    </div>
                    <div class='col-2'>
                      <p>Volta</p>
                    </div>
                    <div class='col text-end'>
                    </div>
                  </div>

                  <div *ngFor="let voo of seguimento.Voos; let k = index">
                    <div class='row jc-se'>
                      <div class='col-2 as-c'>
                        <img src="assets/img/cias/{{voo.CiaMarketing}}.png" alt="{{voo.CiaMarketing}}" title="{{aereo.CiaValidadora}}" />
                      </div>
                      <div class='col-3'>
                        <p *ngIf="k == 0 || (k > 0 && voo.AeroportoPartida == seguimento.Voos[k - 1].AeroportoChegada)">{{ voo.AeroportoPartida }}</p>
                        <p *ngIf="k > 0 && voo.AeroportoPartida != seguimento.Voos[k - 1].AeroportoChegada" style="color:red">{{ voo.AeroportoPartida }}*</p>
                        <p style="font-size: 13px;font-weight: bold;">{{ voo.DataPartida | date : 'dd/MM/yyyy HH:mm'}}</p>
                      </div>
                      <div class='col-3 text-center'>
                        <div class='col voo-detalhes'>
                          <p>{{voo.Duracao.substring(0,5)}}</p>
                          <app-trecho-simbol></app-trecho-simbol>
                        </div>
                      </div>
                      <div class='col-2'>
                        <p>{{ voo.AeroportoChegada }}</p>
                        <p style="font-size: 13px;font-weight: bold;">{{voo.DataChegada | date : 'dd/MM/yyyy HH:mm'}}</p>
                      </div>
                    </div>

                    <div class='row'>
                      <div class='col'>
                        {{'Voo' | translate}} N°{{voo.NumeroVoo}} | {{"Classe" | translate}}: {{voo.ClasseTarifaria}} | {{"Bag." | translate}}: {{seguimento.BagagemQuantidade}} | {{seguimento.FamiliaTarifaria
                }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="preco">
              <div class='row '>
                <div class='col text-center'>
                  <h5> {{aereo.MoedaListagem.Simbolo}} {{aereo.TarifasListagemTotal | currency:'BRL':'':'2.2-2' }}</h5>
                  <p>+ {{'Taxas' | translate}}: {{aereo.MoedaListagem.Simbolo}} {{aereo.TaxasListagemTotal |  currency:'BRL':'':'2.2-2'}}</p>
                  <p *ngIf="aereo.TaxasDUListagemTotal>0">+ {{'RC' | translate}}: {{aereo.MoedaListagem.Simbolo}} {{aereo.TaxasDUListagemTotal |  currency:'BRL':'':'2.2-2'}}</p>
                </div>
              </div>

              <!-- <div class='row' *ngIf="!Laminas">
                <div class='col text-center'>
                  <label class="btn btn-success" *ngIf="!aereo.selecionado">
                    <input type="checkbox" class="checked-btn" (click)="selecionou(aereo)" /><span>{{'Adicionar' | translate}}</span>
                  </label>
                  <label class="btn btn-outline-success" *ngIf="aereo.selecionado">
                    <input type="checkbox" class="checked-btn" (click)="removeu(aereo)" /><span>{{'Remover' | translate}}</span>
                  </label>
                </div>
              </div> -->
            </div>
          </div>
        </div>

      </div>

    </div>


    <div class='row mt-3' *ngIf="pacoteReserva.Servicos != null">
      <div class='container col-md-12'>
        <h4 class="col-md-5">{{"Serviço(s) Incluído(s)" | translate }}</h4>
      </div>
    </div>
    <div class="row" *ngIf="pacoteReserva.Servicos != null">
      <div class="container col-md-12">
        <div *ngFor="let servico of pacoteReserva.Servicos; let i = index">
          <div class="card-servicos all">
            <div class="row g-0">
              <div class="col-md-3">
                <img src="{{
                      servico.ImagemPrincipal != null && servico.ImagemPrincipal != ''
                        ? servico.ImagemPrincipal
                        : 'assets/logo/logo-nova-colorida.svg'
                    }}" class="img-fluid rounded-start" alt="background image">
              </div>
              <div class="col-md-6">
                <div class="card-body">
                  <div class="row mb-3 col-9 card-body" [ngSwitch]="servico.CategoriaServico">
                    <h5 class="fonte-cabecalho-card"> {{servico.Titulo}}
                      <span *ngSwitchCase="0">({{'REGULAR' | translate}})</span>
                      <span *ngSwitchCase="1">({{'PRIVATIVO' | translate}})</span>
                      <span *ngSwitchCase="2">({{'LUXO' | translate}})</span>
                    </h5>
                  </div>
                  <div>
                    <button class="btn btn-success" (click)="abrirModalServico(contentServicoDetalhes, servico)" *ngIf="!buscando">{{"Detalhes do Serviço" | translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class='row mb-3 mt-3'>
      <div class='container col-md-12 row'>
        <h4 class="col-md-5">{{"Selecione um Hotel" | translate }}</h4>
      </div>
    </div>

    <app-view-loading *ngIf="buscando"></app-view-loading>


    <div class="card-section row">
      <div class="col-md-4 mt-3 mb-3" *ngFor="let hotel of pacoteReserva.Hoteis; let i = index">
        <div class="card">
          <img class="card-img-top" src="{{ hotel.HotelImagem != null && hotel.HotelImagem != '' ? hotel.HotelImagem : backgroundimage }}">
          <div class="card-body">
            <h5 class="card-title">{{hotel.Nome}}</h5>
            <h6 class="card-subtitle mb-2 text-muted"><i class="fas fa-star" *ngFor='let i of [].constructor(hotel.Estrelas)'></i></h6>
            <p class="card-text">{{hotel.Endereco}}</p>
            <div class="row justify-content-between m-3">
              <a (click)="abrirModal(contentHotelDetalhes, hotel, i)" class="col-md-5 nav-link">
                {{'Detalhes' | translate}}
              </a>
              <a (click)="abrirModal(contentHotelQuartos, hotel, i);" class="nav-link col-md-7">{{"Opções de Quartos" | translate}}</a>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <h6>{{"A partir de" | translate}}:</h6>
                <h3>{{hotel.Quartos[0].MoedaListagem.Simbolo}}{{hotel.Soma| currency :'BRL':'':'1.2-2'}}</h3>
              </div>
              <div>
                <button (click)="selecionarHotel(hotel)" [disabled]="hotelSelecionado(hotel)" class="btn btn-success float-end mt-2">{{ hotelSelecionado(hotel) ? "Selecionado" : "Selecionar" |
                    translate}}</button>
                <button *ngIf="hotelSelecionado(hotel)" class="btn btn-block btn-outline-success float-end mt-2" (click)="removerHotel(hotel)">{{"Remover" | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #contentHotelQuartos let-modal>
  <app-hotel-quartos [Hotel]="objHotel" [pacote]="true" (selecionouHotel)="atualizaHotel($event)" [ValorServico]="totalValorListagemServico"></app-hotel-quartos>
</ng-template>

<ng-template #contentHotelDetalhes let-modal>
  <app-hotel-detalhes [hotel]="objHotel" [pacote]="false"></app-hotel-detalhes>
</ng-template>

<ng-template #contentServicoDetalhes let-modal>
  <app-servico-detalhes [objServico]="objModal"></app-servico-detalhes>
</ng-template>
<!-- <ng-template #contentDetalhesPacote let-modal>
  <div class="modal-header">
    <h3 class="modal-title text-center" id="modal-basic-title">{{PacoteDetalhes.Pacote.Nome}}</h3>
    <button type="button" class="close btn" aria-label="Close" (click)="fecharModal()"></button>
  </div>
  <div class="modal-body">
    <img [src]="PacoteDetalhes?.PacoteImagens[0]?.Imagem" class="img-fluid card-img-top"
      style="max-height: 500px; object-fit: fill" [alt]="PacoteDetalhes?.Nome" />
    <div class="p-2">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" *ngFor="let inclui of PacoteDetalhes?.PacotesInclui; let i = index">
          <button class="nav-link" [ngClass]="i == 0 ? 'active' : ''" id="tabs{{i}}" data-bs-toggle="tab"
            [attr.data-bs-target]="'#tab' + i" type="button" style="text-decoration: none !important;" role="tab"
            [attr.aria-controls]="'tab' + i" [attr.aria-selected]="i == 0 ? true : false">{{inclui?.Titulo}}
          </button>
        </li>
      </ul>
      <div class="tab-content p-2 mt-2" id="myTabContent">
        <div *ngFor="let inclui of PacoteDetalhes?.PacotesInclui; let i = index" class="tab-pane fade" id="tab{{i}}"
          [ngClass]="i == 0 ? 'show active' : ''" role="tabpanel" [attr.aria-labelledby]="'tabs' + i"
          [innerHTML]="inclui?.Descritivo"></div>
      </div>
    </div>
  </div>
</ng-template> -->
