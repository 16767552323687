// import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { BetoCarrero } from '../../../model/turismo/beto-carrero.model';
// import { Usuario } from './../../../model/usuario.model';
// import { BetoCarreroService } from "src/app/services/turismo/beto-carrero.service";
// import { LoginService } from '../../../services';
// import * as moment from 'moment';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BetoCarreroService } from "src/app/services/turismo/beto-carrero.service";
import { LoginService } from '../../../services';
import { BetoCarrero } from '../../../model/turismo/beto-carrero.model';
import { Usuario } from './../../../model/usuario.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import { __read, __spreadArrays } from 'tslib';

@Component({
  selector: 'beto-reserva',
  templateUrl: './beto-reserva.component.html',
  styleUrls: ['./beto-reserva.component.scss']
})
export class BetoReservaComponent implements OnInit {

  @Input() Ingresso: BetoCarrero;
  @Output() alterou = new EventEmitter<any>();

  Usuario: Usuario = new Usuario();

  carregando: boolean = false;

  mensagemSucesso: string;
  mensagensErro: string[];

  politica: any = null;

  dataAtual;
  
  constructor(
    private betoCarreroService: BetoCarreroService,
    private loginService: LoginService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.dataAtual = moment().format('YYYY-MM-DDTHH:mm:ss');
    this.Usuario = this.loginService.user();
    this.buscaPolitica()
  }

  buscaPolitica() {
    this.mensagensErro = [];
    this.mensagemSucesso = '';
    this.carregando = true;
    
    this.betoCarreroService.buscaPolitica(this.Ingresso.Id, this.Ingresso.ReservaId).subscribe(
      dados => {
        this.carregando = false;
        this.politica = dados.Politica;
        this.Ingresso = dados.Ingresso;

        this.alterou.emit(this.Ingresso);

        this.mensagemSucesso = `Ingresso ${this.Ingresso.Titulo} tarifado com sucesso`;
      }, erro => {
        this.carregando = false;
        this.mensagensErro = this.errorHandler(erro);
        // this.alterou.emit(erro.error.dados.Ingresso);
        erro.error.dados.Erros.forEach(erro => this.mensagensErro.push(erro));
        console.log(erro);
      }
    );
  }

  reservar() {
    this.mensagensErro = [];
    this.mensagemSucesso = '';
    this.carregando = true;

    this.definirAgenciaId();

    var reservarIngresso = {
      Ingresso: this.Ingresso,
      AgenciaId: this.Ingresso.AgenciaId,
      IngressoId: this.Ingresso.Id,
      Politica: this.politica,
    }
    
    this.betoCarreroService.reservarIngresso(reservarIngresso).subscribe(
      dados => {
        this.carregando = false;
        if (dados.Erros && dados.Erros.length > 0) {
          this.mensagensErro = dados.Erros;
          return;
        }
        this.Ingresso = dados.Ingresso;
        this.alterou.emit(this.Ingresso);
        this.mensagemSucesso = `Ingresso ${this.Ingresso.Titulo} reservado com sucesso`;
      },
      erro => {
        this.alterou.emit(this.Ingresso);
        this.Ingresso.Status != 1;
        this.carregando = false;
        this.mensagensErro = this.errorHandler(erro);
        console.error(erro);
      }
    );
  }

  definirAgenciaId() {
    if (this.Usuario.AgenciaId != null) {
      this.Ingresso.AgenciaId = this.Usuario.AgenciaId;
    } else {
      let agencia = window.localStorage.getItem("g8h7d8");
      if (agencia != null) {
        let a = JSON.parse(atob(agencia));
        console.log(a)
        this.Ingresso.AgenciaId = a.Id;
      }
    }
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  public errorHandler(erro): string[] {

    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
      case 400:

        if (erro.error && erro.error.mensagem)
          mensagem.push(erro.error.mensagem);
        else
          mensagem.push("Houve algum erro na execução, tente novamente!");

        break;
      case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
      case 404: mensagem.push(erro.error.mensagem); break;
      case 406: mensagem.push(erro.error.mensagem); break;
      // case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ); break;
      case 500: mensagem.push(erro.error.ExceptionMessage); break;
      default: mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      mensagem.push("Houve algum erro na execução, tente novamente!");
    }
    return mensagem;

  }

}
