 
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Reserva do Ingresso' | translate}} - {{Ingresso.Titulo}}</h4>
    <button type="button" class="btn btn-cancelar close" [disabled]="carregando" aria-label="Close" (click)="fecharModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card">
      <div class="card-header">
        <p *ngIf="carregando">Buscando Políticas</p>
        <p *ngIf="!carregando">Politicas de Cancelamento</p>
      </div>
      <div class="card-body" *ngIf="!carregando">
        <h5 class="text-dark">{{Ingresso?.PoliticaCancelamento}}</h5>
        <p *ngIf="mensagensErro.length == 0" class="text-danger" style="font-size: 1.3rem;"><b>{{ (Ingresso.PrazoCancelamento > dataAtual ? ('Data limite cancelamento: ' | translate) : 'Ingresso em Gastos. Prazo excedido: ')}}</b>{{(politica?.DataDeadline | date: 'dd/MM/yyyy')}}</p>
        <p *ngIf="mensagensErro.length > 0" class="text-danger" style="font-size: 1.3rem;">{{"Ocorreu um erro ao tarifar, verifique o ingresso." | translate }}</p>
      </div>
      <div class="card-footer">
        <div *ngIf="politica?.AlterouValor">
          <h5 class="text-danger">{{'ATENÇÃO o valor do hotel alterou!' | translate}}</h5>
          <p><strong>{{'Valor Atualizado:' | translate}} {{Ingresso.MoedaListagem | currency :'BRL':'':'2.2-2'}}
              {{Ingresso.ValorListagem}}</strong></p>
          <p><nb-checkbox [(ngModel)]="politica.CienteAlterouValor" status="danger">{{'Estou Ciente' |
              translate}}</nb-checkbox></p>
        </div>
      </div>
    </div>
  
  </div>
  <div class="modal-footer" style="display:block">
    <div class="row" *ngIf="mensagensErro?.length > 0">
      <div class="alert alert-danger" style="width: 100%">
        <ul>
          <li *ngFor="let mensagem of mensagensErro">{{mensagem | translate}}</li>
        </ul>
      </div>
    </div>
    <div class="row" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
      <div class="alert alert-success" style="width: 100%">
        {{mensagemSucesso | translate}}
      </div>
    </div>
  
    <div class="row">
      <div>
        <button (click)="fecharModal()" [disabled]="carregando" class="btn btn-cancelar" type="submit">{{'Voltar' |
          translate}}</button>
        <button *ngIf="Ingresso.Status == 0"
          [disabled]="carregando || (politica?.AlterouValor && !politica?.CienteAlterouValor) || mensagensErro.length > 0"
          (click)="reservar()" class="btn btn-success" type="submit">{{"Reservar" | translate}}</button>
      </div>
    </div>
  </div>
