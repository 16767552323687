import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CarroService } from 'src/app/services/turismo/carro.service';

@Component({
    selector: 'carro-reserva',
    templateUrl: './carro-reserva.component.html',
    styleUrls: ['./carro-reserva.component.scss']
})
export class CarroReservaComponent implements OnInit {

    @Output() alterou = new EventEmitter<any>();

    @Input() carro: {
        Protecoes: any,
        CondutorDocumento: "",
        CondutorNome: "",
        CondutorSobrenome: "",
        Descricao: string,
        Imagem: any,
        ModeloNome: any,
        Pax: any,
        Portas: any,
        Bagagem: any,
        TemAirCondicionado: any,
        TransmissaoTipo: any,
        Status: any,
        CondutorEmail: any;
        CondutorTelefone: any;
    };

    @Input() paxes: any;

    carregando = false;
    mensagensErro: any[];
    mensagemSucesso: string;
    reservado = false;
    condutores: any[];
    condutor: any;
    mensagemDocumento: boolean;

    constructor(
        private modalService: NgbModal,
        private carroService: CarroService,
    ) { }


    ngOnInit() {
        this.getCondutores();
    }

    getCondutores() {
        this.mensagemDocumento = true;
        this.condutores = this.paxes.filter(x => x.TipoDesc == "ADT" && x.Idade > 20);

        if (this.condutores != null) {
            this.condutor = this.condutores[0];
            this.carro.CondutorNome = this.condutores[0].Nome;
            this.carro.CondutorSobrenome = this.condutores[0].Sobrenome;
            this.mensagemDocumento = false;
        }
    }

    verificarDocumento(): boolean {
        return this.condutor.CPF == null || this.condutor.CPF == "";
    }

    reservar() {
        delete this.mensagensErro;
        delete this.mensagemSucesso;
        this.carregando = true;

        if (this.condutor.CPF == null || this.condutor.CPF == "") {
            this.mensagemDocumento = true;
            this.carregando = false;
            return;
        }
        
        this.carro.CondutorNome = this.condutor.Nome;
        this.carro.CondutorSobrenome = this.condutor.Sobrenome;
        this.carro.CondutorDocumento = this.condutor.CPF;
        this.carro.CondutorEmail = this.condutor.Email;
        this.carro.CondutorTelefone = this.condutor.Telefone;

        this.carroService.reservarCarro(this.carro)
        .subscribe(
            dados => {

                this.carro = dados.Carro;
                this.mensagemSucesso = 'Carro reservado!';
                this.alterou.emit(this.carro);
                this.carregando = false;

            }, 
            erro => {
                this.carregando = false;
                console.log(erro);
                this.mensagensErro = this.errorHandler(erro);
            });
    }

    public errorHandler(erro): string[] {

        let mensagem = [];
        switch (erro.status) {
            case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde!'); break;
            case 400: mensagem.push('Houve algum erro na execução, tente novamente!'); break;
            case 401: mensagem.push('você não tem autorização para executar esta ação!'); break;
            case 404: mensagem.push('Solicitação inválida!'); break;
            case 406: mensagem.push(erro.error.mensagem); break;
            case 500: mensagem.push('Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte'); break;
            default: mensagem.push(erro.statusText);
        }
        if (erro.error != null && erro.error.mensagens != null) {
            mensagem = mensagem.concat(erro.error.mensagens);
        }
        return mensagem;

    }

    fecharModal() {
        this.modalService.dismissAll();
    }

}
