import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Carro } from 'src/app/model';

@Component({
    selector: 'app-carro',
    templateUrl: './carro.component.html',
    styleUrls: ['./carro.component.scss']
})
export class CarroComponent implements OnInit {

    @Input() carro: any;
    @Input() index: number;
    @Output() selecionouCarro = new EventEmitter();
    @Output() removeuCarro = new EventEmitter();

    ProtecaoDescricao: string = '';

    constructor(
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.carro.Protecoes.filter(x => !x.Obrigatorio).map(x => { x.desabilitado = false; return x });
        this.carro.Protecoes.filter(x => x.Codigo == 'PAI').map(x => { x.desabilitado = true; return x });
        this.carro.ValorProtecoes = this.carro.Protecoes.filter(x => x.selecionado).map(x => x.ValorVenda).reduce(function (a, b) {
            return a + b;
        }, 0);

        this.carro.TaxaAdmProtecoes = this.carro.Protecoes.filter(x => x.selecionado).map(x => x.TaxaAdm).reduce(function (a, b) {
            return a + b;
        }, 0);
    }

    abrirModal(content, descricao) {
        this.ProtecaoDescricao = descricao;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true });
    }

    fecharModal() {
        this.modalService.dismissAll();
    }

    selecionarProtecao(protecao, obrigatorio) {
        if (obrigatorio) {
            this.carro.Protecoes.filter(x => x.Obrigatorio && x != protecao).map(x => { x.selecionado = false; return x });
            protecao.selecionado = true;
            this.carro.Protecoes.filter(x => !x.Obrigatorio).map(x => { x.selecionado = false; return x });
            if (protecao.Codigo == 'PJSUP' || protecao.Codigo == 'PJCOM') {
                for (let j = 0; j < this.carro.Protecoes.length; j++) {
                    this.carro.Protecoes[j].desabilitado = (this.carro.Protecoes[j].Codigo == "ALI" || this.carro.Protecoes[j].Codigo == "PAI") ? true : false;
                }
            } else {
                for (let j = 0; j < this.carro.Protecoes.length; j++) {
                    this.carro.Protecoes[j].desabilitado = false;
                }
                this.carro.Protecoes.filter(x => x.Codigo == 'PAI').map(x => { x.desabilitado = true; x.selecionado = false; return x });
            }
        } else {
            if (protecao.Codigo == 'WDW') {
                this.carro.Protecoes.filter(x => x.Codigo == 'WDWP').map(x => { x.selecionado = false; return x });
            } else if (protecao.Codigo == 'WDWP') {
                this.carro.Protecoes.filter(x => x.Codigo == 'WDW').map(x => { x.selecionado = false; return x });
            }
            protecao.selecionado = !protecao.selecionado;
            if (protecao.Codigo == 'ALI') {
                if (protecao.selecionado) {
                    this.carro.Protecoes.filter(x => x.Codigo == 'PAI').map(x => { x.desabilitado = false; return x });
                } else {
                    this.carro.Protecoes.filter(x => x.Codigo == 'PAI').map(x => { x.desabilitado = true; x.selecionado = false; return x });
                }
            }
        }
        this.carro.ValorProtecoes = this.carro.Protecoes.filter(x => x.selecionado).map(x => x.ValorVenda).reduce(function (a, b) {
            return a + b;
        }, 0);

        this.carro.TaxaAdmProtecoes = this.carro.Protecoes.filter(x => x.selecionado).map(x => x.TaxaAdm).reduce(function (a, b) {
            return a + b;
        }, 0);
    }

    selecionou(carro) {
        this.selecionouCarro.emit(carro);
    }

    removeu(carro) {
        this.removeuCarro.emit(carro);
    }

}
