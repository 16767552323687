<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Cancelamento de Reserva' | translate}} </h4>
    <button type="button" class="btn btn-cancelar close" [disabled]="carregando" aria-label="Close" (click)="fecharModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="inline-form-card mb-0 border-top">
        <div>
            <div class="row">
                <div class="col-md-6 align-self-center">
                    <div class="row col-md-12">
                        <div class="col-md-9 align-self-center pl-0">
                            <h4 class="fonte-Roboto">{{Ingresso.Titulo}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-md-3">
                    <p><strong>{{'Data Inclusão' | translate}}:</strong>
                        {{Ingresso.Alteracoes.CriadoEm | date :'dd/MM/yyyy'}}
                    </p>
                </div>
                <div class="col-md-3">
                    <p *ngIf="Ingresso.DataEscolhida"><strong>{{'Data de Visita ao Parque:' | translate}}:</strong>
                        {{Ingresso.DataEscolhida | date :'dd/MM/yyyy'}}</p>
                </div>
                <div class="col-md-3">
                    <p *ngIf="Ingresso.DescricaoTipoProduto"><strong>{{'Tipo de Produto:' | translate}}:</strong>
                        {{Ingresso.DescricaoTipoProduto}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <p><strong>{{'Descricao:' | translate}}:</strong>
                        {{Ingresso.DescricaoCliente}}
                    </p>
                </div>
                <div class="col-md-9">
                    <p *ngIf="Ingresso.Localizador != 0"><strong>{{'Localizador:' | translate}}:</strong>
                        {{Ingresso.Localizador}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 d-flex align-items-center">
                    <p *ngIf="Ingresso.PrazoCancelamento != null"><strong class="text-danger">{{"Prazo de Cancelamento"
                            | translate}}:
                        </strong>{{Ingresso.PrazoCancelamento | date: 'dd/MM/yyyy'}}</p>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-4 align-self-end">
                    <div class="text-right" style="font-size: 20px;">
                        {{'Total' | translate}}:<br>
                        <strong class="texto-Preco">{{Ingresso.MoedaListagem.Sigla}}
                            {{Ingresso.ValorListagem | currency:'BRL':'':'2.2-2'}}</strong><br>
                        {{'Taxa' | translate}}: {{Ingresso.MoedaListagem.Sigla}}
                        {{Ingresso.Taxa | currency:'BRL':'':'2.2-2'}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3" *ngIf="mensagensErro?.length > 0">
        <div class="alert alert-danger" style="width: 100%">
            <ul>
                <li *ngFor="let mensagem of mensagensErro">{{mensagem | translate}}</li>
            </ul>
        </div>
    </div>
    <div class="row mt-3" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
        <div class="alert alert-success" style="width: 100%">
            {{mensagemSucesso | translate}}
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12 d-flex justify-content-around row">
            <button (click)="fecharModal()" [disabled]="carregando" class="btn btn-danger col-3" type="submit">{{'Voltar' |
                translate}}</button>
            <button *ngIf="Ingresso.Status != 8" [disabled]="carregando" (click)="cancelar()"
                class="mr-5 btn btn-success col-4" type="submit">{{'Cancelar reserva' | translate}}</button>
        </div>
    </div>
</div>