<div class="card mb-3 all">
    <div class="card-body row">
        <div class="col-12 col-md-3 d-flex justify-content-center">
            <img style="object-fit: contain; object-position: center; width: 100%;" src="{{carro.Imagem}}" alt="">
        </div>
        <div class="col-12 col-md-6">
            <h4 class="p-0 m-0">{{carro.Descricao}}</h4>
            <p class="">{{carro.ModeloNome}}</p>
            <p class="text-justify">
                <strong>{{'Ocupantes'}}:</strong> {{carro.Pax}}<br>
                <strong>Núm. {{'portas'}}:</strong> {{carro.Portas}}<br>
                <strong>{{'Bagagem'}}:</strong> {{carro.Bagagem}}<br>
                <strong>{{'Ar Condicionado'}}:</strong> {{carro.TemAirCondicionado?"Sim":"Não"}}<br>
                <strong>{{'Transmissão'}}:</strong> {{carro.TransmissaoTipo}}<br><br>
            </p>
            <div class="accordion" [id]="carro.Id">
                <!-- <a class="adc_protecao float-end">Adicionar Proteção</a> -->
                <div class="accordion-item">
                    <h2 class="accordion-header" [id]="'heading-' + index">
                        <button class="accordion-button collapsed float-end mb-4" type="button"
                            data-bs-toggle="collapse" attr.data-bs-target="#collapse-{{index}}" aria-expanded="true"
                            attr.aria-controls="collapse-{{index}}">
                            Opções de Proteção
                        </button>
                    </h2>
                    <div [id]="'collapse-' + index" class="accordion-collapse collapse"
                        attr.aria-labelledby="heading-{{index}}" attr.data-bs-parent="#accordion-{{index}}">
                        <div class="accordion-body p-2">
                            <div class="row" *ngFor="let protecao of carro.Protecoes; index as i">
                                <div class="col-md-9 mb-1" style="font-size: 14px;"
                                    *ngIf="protecao.Obrigatorio">
                                    <input type="radio" class="me-2" 
                                        (click)="selecionarProtecao(protecao, protecao.Obrigatorio)"
                                        [name]="'protObr-' + index"
                                        [checked]="protecao.Codigo=='PJBAS' || protecao.selecionado">
                                    <a class="link-protecao cursor-pointer" style="cursor: pointer;"
                                        (click)="abrirModal(contentDescricaoProtecao, protecao.Descricao)">{{protecao.Titulo}}
                                        ({{protecao.Codigo}} )
                                    </a>
                                </div>
                                <div class="col-md-9 mb-1" *ngIf="protecao.Obrigatorio == false">
                                    <input type="checkbox" class="me-2" [name]="'protOpc-' + index"
                                        [disabled]="protecao.desabilitado"
                                        (click)="selecionarProtecao(protecao,protecao.Obrigatorio)"
                                        [checked]="protecao.selecionado">
                                    <a class="link-protecao" style="cursor: pointer; font-size: 14px;"
                                        (click)="abrirModal(contentDescricaoProtecao, protecao.Descricao)">{{protecao.Titulo}}
                                        ({{protecao.Codigo}} )
                                    </a>
                                </div>
                                <div class="col-md-3 mb-1 text-right" style="font-size: 14px;"><strong>
                                        {{carro.MoedaListagem.Sigla}} {{protecao.ValorVenda | currency
                                        :'BRL':'':'1.2-2'}}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3">
            <div class="h-100 d-flex flex-column align-items-center justify-content-center">
                <div class='row text-center'>
                    <div class='col d-flex flex-column justify-content-center align-items-center text-center as-c'>
                        <h6>{{"Fornecedor" | translate}}</h6>
                        <img src="assets/img/ciascarros/{{ carro.Fornecedor }}.png" alt="{{ carro.Fornecedor }}"
                            title="{{ carro.Fornecedor }}" />
                    </div>
                </div>

                <div class='row mt-2'>
                    <div class='col d-flex flex-column justify-content-center align-items-center text-center '>
                        <h5> {{carro.MoedaListagem.Sigla}} {{carro.ValorListagem | currency:'BRL':'':'2.2-2' }}</h5>
                        <p>+ {{'Taxas' | translate}}: {{carro.MoedaListagem.Sigla}} {{carro.TaxaAdm +
                            carro.TaxaAdmProtecoes + carro.TaxaExtra | currency :'BRL':'':'1.2-2'}}</p>
                    </div>
                </div>

                <div class='row'>
                    <div class='col d-flex flex-column justify-content-center align-items-center text-center '>
                        <button class="btn btn-success checked-btn" *ngIf="!carro.selecionado"
                            (click)="selecionou(carro)"><span>{{'Adicionar' | translate}}</span> </button>
                        <button class="btn btn-outline-success checked-btn" *ngIf="carro.selecionado"
                            (click)="removeu(carro)"><span>{{'Remover' | translate}}</span> </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contentDescricaoProtecao let-modal>
    <div class="modal-content">
        <div class="modal-header" style="cursor: pointer;">
            <h5 class="modal-title" id="exampleModalLabel">{{ "Detalhes da Proteção" }}</h5>
            <i class="fas fa-times" (click)="fecharModal()"></i>
        </div>
        <div class="modal-body">
            <div class="row">
                <a>{{ProtecaoDescricao}}</a>
            </div>
        </div>
    </div>
</ng-template>