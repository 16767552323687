import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Busca, Carro, Paginacao } from 'src/app/model';
import { CarroService } from 'src/app/services/turismo/carro.service';

@Component({
    selector: 'app-lista-carro',
    templateUrl: './lista-carro.component.html',
    styleUrls: ['./lista-carro.component.scss']
})
export class ListaCarroComponent implements OnInit, AfterViewInit {

    @Output() selecionou = new EventEmitter();
    @Output() avancar = new EventEmitter();
    @Output() removeu = new EventEmitter();
    @Input() busca: Busca;
    @Input() Orcamento: Carro[] = [];
    @Input() events: Observable<void>;
    private eventsSubscription: Subscription;

    constructor(
        private carroService: CarroService,
        public route: ActivatedRoute,
        public router: Router
    ) { }

    visualizaCarro: boolean = true;

    ngAfterViewInit() {

    }

    ngOnInit() {
        this.busca = this.busca.decodeBuscaNovo(window.sessionStorage.getItem('busca'));
        this.buscar();
        this.eventsSubscription = this.events.subscribe((busca) => this.refazerBusca(busca));
    }

    rebusca: boolean = false;
    buscando: boolean = false;
    carros: Carro[] = [];
    paginacao: Paginacao = new Paginacao();
    buscasAtivas = [];
    protecoesObrigatoriasMovida: string[] = ["PJSUP", "PJCOM", "PJBAS"];
    contador: number = 0;
    mensagemErro: string = '';

    Fornecedores: String[] = [];
    LocaisOrigem: String[] = [];
    LocaisDevolucao: String[] = [];

    buscar(buscaCarro = null) {
        if (buscaCarro == null) {
            buscaCarro = this.busca.getBuscaCarro();
        }
        this.mensagemErro = '';
        buscaCarro.Rebusca = this.rebusca;

        if (!this.buscando) {
            this.carros = []
        }

        this.buscando = true;

        this.carroService.buscarCarro(buscaCarro, this.paginacao.NumeroPagina)
            .subscribe(
                dados => {
                    this.buscasAtivas = JSON.parse(dados.MetaData.BuscasAtivas);

                    this.busca.guid = dados.MetaData.Guid;
                    buscaCarro.Guid = dados.MetaData.Guid;

                    this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
                    this.paginacao.TotalItens = dados.MetaData.TotalItens;
                    this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
                    this.paginacao.ItensPorPagina = dados.MetaData.ItensPorPagina;

                    window.sessionStorage.setItem('busca', JSON.stringify(this.busca));

                    this.carros = dados.Items.map(x => {
                        x.Id = x.Id * -1;
                        x.selecionado = this.carroSelecionado(x);
                        return x;
                    });

                    for (let i = 0; i < this.carros.length; i++) {
                        for (let j = 0; j < this.carros[i].Protecoes.length; j++) {
                            this.carros[i].Protecoes[j].Obrigatorio = this.protecoesObrigatoriasMovida.includes(this.carros[i].Protecoes[j].Codigo);
                            this.carros[i].Protecoes[j].selecionado = this.carros[i].Protecoes[j].Codigo == "PJBAS" ? true : false;
                        }
                        this.carros[i].ValorProtecoes = this.carros[i].Protecoes.filter(x => x.selecionado).map(x => x.Valor).reduce((a, b) => a + b, 0);
                    }

                    this.contador++

                    if (this.buscasAtivas.length > 0 && (window.location.href.split('/').indexOf('listas') != -1 || window.location.href.split('/').indexOf('adicionar') != -1)) {
                        setTimeout(() => {
                            this.buscar(buscaCarro);
                        }, 3000);
                    } else {
                        if (this.carros.length == 0) {
                            this.mensagemErro = 'Nenhum carro encontrado';
                        }
                        this.buscando = false;
                    }

                },
                error => {
                    console.log(error);
                    this.buscando = false;
                    this.mensagemErro = error.error.mensagem;
                }
            );

        this.busca.dataOrigem = moment(this.busca.dataOrigem).format('YYYY-MM-DD');
        this.busca.dataDevolucao = moment(this.busca.dataDevolucao).format('YYYY-MM-DD');
    }

    buscarPagina(pagina) {
        window.scroll(0, 0);
        this.paginacao.NumeroPagina = pagina;
        this.buscar();
    }

    refazerBusca(busca) {
        if (this.aba() == 'carro') {
            this.busca = busca;
            this.busca.primeira = false;
            window.scroll(0, 0);
            this.paginacao.NumeroPagina = 1;
            this.buscando = true;
            this.buscar();
        }
    }

    rotaAvancar() {
        this.avancar.emit('');
    }

    selecionarCarro(carro) {
        carro.selecionado = true;
        this.Orcamento.push(carro);
        this.selecionou.emit(carro);
    }

    carroSelecionado(carro) {
        const dado = this.Orcamento.filter(x => x.Id == carro.Id);
        return dado.length > 0;
    }

    removerCarro(carro) {
        carro.selecionado = false;
        let indice = -1;
        for (let i = 0; i < this.Orcamento.length; i++) {
            if (this.Orcamento[i].Id == carro.Id) {
                indice = i;
            }
        }
        if (indice >= 0) {
            this.Orcamento.splice(indice, 1);
            this.removeu.emit(carro);
        }
    }


    aba() {
        if (this.route.snapshot.params.tipo != null) {
            return this.route.snapshot.params.tipo;
        } else {
            let x = this.router.url.split("/");

            return x[x.length - 1];
        }
    }
}
