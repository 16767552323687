import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private translate: TranslateService) { }

  fazerTraducao(texto: string): string {
    try {
        return this.translate.instant(texto);
    } catch (e) {
        console.log(e);
        return texto;
    }
  }

  // fazerTraducao2(string): string {
  //   try {
  //     return this.translate.instant("Bom dia!");
  //   } catch (e) {
  //     console.log(e);
  //     return "";
  //   }
  // }
}
