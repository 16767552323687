<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cancelar Carro</h4>
    <i class="fas fa-times" (click)="fecharModal()"></i>
</div>
<div class="modal-body">


    <div class="card">
        <div class="card-header">
            <h4>{{carro.Descricao}}</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-9 ">
                    <div>
                        <div class="row" data-gutter="20">
                            <div class="col-md-2 ">
                                <div>
                                    <img class="img-fluid img-thumbnail" [src]="carro.Imagem" alt="">
                                </div>
                            </div>
                            <div class="col-md-10 ">
                                <p style="margin: 20px 0 20px;">
                                    {{carro.ModeloNome}}
                                </p>
                            </div>
                            <div class="col-md-12">
                                <div class="col-md-4">
                                    <strong>{{'Ocupantes'}}:</strong> {{carro.Pax}}
                                </div>
                                <div class="col-md-4">
                                    <strong>Núm. {{'portas'}}:</strong> {{carro.Portas}}
                                </div>
                                <div class="col-md-4">
                                    <strong>{{'Bagagem'}}:</strong> {{carro.Bagagem}}
                                </div>
                                <div class="col-md-4">
                                    <strong>{{'Ar Condicionado'}}:</strong> {{carro.TemAirCondicionado?"Sim":"Não"}}
                                </div>
                                <div class="col-md-4">
                                    <strong>{{'Transmissão'}}:</strong> {{carro.TransmissaoTipo}}
                                </div>
                            </div>
                        </div>
                        <div class="row" data-gutter="20">
                            <ul class="list-unstyled">
                                <li *ngFor="let protecao of carro.Protecoes; index as i">
                                    {{protecao.Titulo}} ({{protecao.Codigo}})
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-3">
                    <p style="font-size: larger;">Condutor: </p>
                </div>
                <div class="col-sm-4 input-group has-calendar-icon">
                    {{carro.CondutorNome}} {{carro.CondutorSobrenome}}
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer" style="display:block">
    <div class="row" *ngIf="mensagensErro?.length > 0">
        <div class="alert alert-danger" style="width: 100%">
            <ul>
                <li *ngFor="let mensagem of mensagensErro">{{mensagem}}</li>
            </ul>
        </div>
    </div>
    <div class="row" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
        <div class="alert alert-success" style="width: 100%">
            {{mensagemSucesso}}
        </div>
    </div>

    <div class="row">
        <div>
            <button *ngIf="carro.Status == 3" [disabled]="carregando" (click)="cancelar()" class="btn btn-success"
          type="submit">Cancelar</button>
        </div>
    </div>
</div>
