<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Reserva de {{"Serviço" | translate }}</h4>
</div>
<div class="modal-body" *ngIf="!carregando">
  <div class="politica">
    <div class='row'>
      <h4 class="title">{{servico.Titulo}}</h4>
    </div>
    <div class='row'>
      <div class='col'>
        <p [innerHTML]="servico.Descricao"></p>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <p class="subtitle">{{"Data do Serviço" | translate }}: <span *ngFor="let d of datas" [ngValue]="d">
            <strong>{{d.format('DD/MM/YYYY')}}</strong>
        </span></p>
      </div>

    </div>
  </div>

  <div class="perguntas">
    <div class='row' *ngIf="politica?.Servico?.Perguntas?.length > 0">
      <h3 class="title">{{"Por favor, responda as perguntas abaixo" | translate}}:</h3>
      <div *ngFor="let pergunta of politica?.Servico?.Perguntas">
        <div class="row">
          <div class="col">
            <p [innerHTML]="pergunta.Pergunta"></p>
          </div>
          <!-- <div class="col">
            <input autocomplete="off" type="text" class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{ pergunta.Codigo }}" >
          </div> -->

          <div class="form-group col" *ngIf="pergunta.Tipo == 8 && orcamento?.Hoteis?.length>0">
            <select class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}">
              <option value="">{{"Selecione um hotel..."| translate}}</option>
              <option *ngFor="let hotel of hoteis">{{hotel}}</option>
            </select>
          </div>
          <div class="form-group col" *ngIf="pergunta.Tipo == 7 && orcamento?.Aereos?.length>0">
            <select class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}">
              <option value="">{{"Selecione um voo..."| translate}}</option>
              <option *ngFor="let voo of voos">{{voo}}</option>
            </select>
          </div>
          <div class="form-group col" *ngIf="pergunta.Tipo == 0 || (pergunta.Tipo == 8 && orcamento?.Hoteis?.length==0) || (pergunta.Tipo == 7 && orcamento?.Aereos?.length==0)">
            <input autocomplete="off" type="text" class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}">
          </div>
          <div class="form-group col" *ngIf="pergunta.Tipo == 1">
            <textarea cols="10" rows="3" class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}"></textarea>
          </div>
          <div class="form-group col" *ngIf="pergunta.Tipo == 4">
            <input autocomplete="off" mask="00/00/0000" type="text" class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}" placeholder="DD/MM/YYYY">
          </div>
          <div class="form-group col" *ngIf="pergunta.Tipo == 5">
            <input autocomplete="off" type="number" class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}">
          </div>
          <div class="form-group col" *ngIf="pergunta.Tipo == 10">
            <input autocomplete="off" type="text" class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}">
          </div>
          <div class="form-group col" *ngIf="pergunta.Tipo == 9">
            <app-typeahead name="AeroportoAutoComplete" [lista]="data" [searchText]="pergunta.Resposta" (searchFunction)="onChangeAeroporto($event)" (selectItem)="selecionaAeroporto($event, pergunta)">
              <input autocomplete="off" placeholder="{{'Aeroporto' | translate}}" class="form-control full-width" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}"  />
            </app-typeahead>
          </div>
        </div>
        <div class="form-group col" *ngIf="pergunta.Tipo == 8 && orcamento?.Hoteis?.length>0">
          <p style="font-size: larger;">{{"Caso não selecionado, informar o hotel" | translate}}</p>
        </div>
        <div class="form-group col" *ngIf="pergunta.Tipo == 8 && orcamento?.Hoteis?.length>0">
          <div class="form-group">
            <input autocomplete="off" type="text" class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}" placeholder="{{'Dados do hotel' | translate}}">
          </div>
        </div>
        <div class="form-group col" *ngIf="pergunta.Tipo == 7 && orcamento?.Aereos?.length>0">
          <p style="font-size: larger;">{{"Caso não selecionado, informar o voo" | translate}}</p>
        </div>
        <div class="form-group col" *ngIf="pergunta.Tipo == 7 && orcamento?.Aereos?.length>0">
          <div class="form-group">
            <input autocomplete="off" type="text" class="form-control input-sm" [(ngModel)]="pergunta.Resposta" name="inputPergunta{{pergunta.Codigo}}" placeholder="{{'Dados do voo' | translate}}">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" style="display:block">
  <app-loading-small *ngIf="carregando"></app-loading-small>

  <div class="row" *ngIf="mensagensErro?.length > 0">
    <div class="alert alert-danger" style="width: 100%">
      <ul>
        <li *ngFor="let mensagem of mensagensErro">{{mensagem}}</li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="mensagemSucesso?.length > 0" style="width: 100%">
    <div class="alert alert-success" style="width: 100%">
      {{mensagemSucesso}}
    </div>
  </div>
  <div class="row">
    <div class='col text-end'>
      <button class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="fechou.emit()">{{"Voltar" | translate}}</button>
      <button *ngIf="servico.Status == 0" [disabled]="carregando || !perguntasValidas()" (click)="reservar()" class="btn btn-success" type="submit">{{"Reservar" | translate }}</button>
    </div>
  </div>
</div>
