import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { CidadeService, AeroportoService } from 'src/app/services';
import { Router, ActivatedRoute } from "@angular/router";
import { Busca, Aeroporto, Quarto, Trecho, Cidade } from 'src/app/model';
import * as moment from "moment";
import { CarroService } from 'src/app/services/turismo/carro.service';

@Component({
    selector: 'app-rebusca',
    templateUrl: './rebusca.component.html',
    styleUrls: ['./rebusca.component.scss']
})
export class RebuscaComponent implements OnInit, AfterViewInit {

    @Input() busca: Busca;

    @Input() tipoBusca;

    @Output() rebuscou = new EventEmitter<any>();

    cidades = [];
    lojas = [];

    constructor(
        private _router: Router,
        public route: ActivatedRoute,
        private cidadeService: CidadeService,
        private aeroportoService: AeroportoService,
        private carroService: CarroService
    ) { }

    ngOnInit() {

        this.busca = this.busca.decodeBuscaNovo(window.sessionStorage.getItem('busca'));

        this.busca.trechos = this.busca.trechos.map(x => {
            x.dataIda = moment(x.dataIda);
            x.dataVolta = moment(x.dataVolta);
            x.minDataIda = moment(x.minDataIda);
            x.minDataVolta = moment(x.minDataVolta);
            return x;
        });

        this.minDate = moment().format('YYYY-MM-DD');
    }

    ngAfterViewInit() {
        this.busca.dataOrigem = moment(this.busca.dataOrigem).format('YYYY-MM-DD');
        this.busca.dataDevolucao = moment(this.busca.dataDevolucao).format('YYYY-MM-DD');

        if (this.busca.tipoBusca.aereo) {
            this.busca.localOrigem.display_field = this.busca.trechos[0].AeroportoChegada.display_field;
            this.busca.localDevolucao.display_field = this.busca.trechos[0].AeroportoChegada.display_field;
        }
    }

    timerCidade = null;
    buscaCidadeDebounce(val: string) {
        clearTimeout(this.timerCidade);
        this.timerCidade = setTimeout(() => {
            this.buscaDados(val);
        }, 600);
    }

    buscaDados(val: string) {
        if (this.tipoBusca == 'aereo') {
            this.aeroportoService.buscarPorParametro(val, 1).subscribe(
                dados => {
                    this.cidades = dados.Items.map(x => {
                        x.display_field = `${x.Iata} - ${x.Descricao} / ${x.Cidade.Nome} - ${x.Cidade.Estado.Iata} / ${x.Cidade.Estado.Pais.Iata}`;
                        return x;
                    });
                },
                erro => {
                    console.log(erro);
                    this.cidades = [];
                }
            );
        } else {
            this.cidadeService.buscarOrcamento(val, 1).subscribe(
                dados => {
                    this.cidades = dados.Items.map(x => {
                        x.display_field =
                            x.Nome + " - " + x.Estado.Nome + " / " + x.Estado.Pais.Nome;
                        return x;
                    });
                },
                erro => {
                    console.log(erro);
                    this.cidades = [];
                }
            );
        }
    }

    selecionaCidadeOrigem(item, trecho) {
        if (this.tipoBusca == 'aereo') {
            trecho.AeroportoPartida = item;
            trecho.cidadeOrigem = item.Cidade;
        } else {
            trecho.cidadeOrigem = item;
        }
    }

    selecionaCidadeDestino(item, trecho) {
        if (this.tipoBusca == 'aereo') {
            trecho.AeroportoChegada = item;
            trecho.cidadeDestino = item.Cidade;
            trecho.cidadeDestino.display_field =
                trecho.cidadeDestino.Nome +
                " - " +
                trecho.cidadeDestino.Estado.Nome +
                " / " +
                trecho.cidadeDestino.Estado.Pais.Nome;
        } else {
            trecho.cidadeDestino = item;
        }
    }

    setDataIda(event, trechos, indice) {

        trechos[indice].minDataVolta = moment(trechos[indice].dataIda).add(1, 'days');
        if (this.busca.nrnoites != null && this.busca.nrnoites != 0) {
            trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
                this.busca.nrnoites,
                'days'
            );
        }
        else {
            trechos[indice].dataVolta = trechos[indice].minDataVolta;
        }

        if (indice > 0) {
            trechos[indice].minDataIda = moment(trechos[indice].dataIda).add(1, 'days');
        }

        for (let i = indice + 1; i < trechos.length; i++) {
            if (i == 1) {
                trechos[i].minDataIda = moment(trechos[indice].dataIda).add(1, 'days');
            }
            if (trechos[i].minDataIda > trechos[i].dataIda) {
                trechos[i].dataIda = trechos[i].minDataIda;
            }
        }
        this.GetDateDiffIda(event, trechos, indice);
    }

    buscou() {
        this.busca.trechos = this.busca.trechos.map(x => {
            x.dataIda = moment(x.dataIda);
            x.dataVolta = moment(x.dataVolta);
            x.minDataIda = moment(x.minDataIda);
            x.minDataVolta = moment(x.minDataVolta);
            return x;
        });

        if (this.tipoBusca == 'carro') {
            this.busca.trechos[0].dataIda = moment(this.busca.dataOrigem).format('YYYY-MM-DD');
            this.busca.trechos[0].dataVolta = moment(this.busca.dataDevolucao).format('YYYY-MM-DD');
        } else {
            this.busca.dataOrigem = moment(this.busca.dataOrigem).format('YYYY-MM-DD');
            this.busca.dataDevolucao = moment(this.busca.dataDevolucao).format('YYYY-MM-DD');
        }

        this.rebuscou.emit(this.busca);
        console.log("Rebusca, data de visita do Beto Carrero" + this.busca.dataVisitaBetoCarrero);
    }

    onChangeNoites(event, trechos, indice) {
        trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
            this.busca.nrnoites,
            'days'
        );
    }

    GetDateDiffIda(event, trechos, indice) {
        if (trechos[indice].dataVolta != null) {
            let diffdays = trechos[indice].dataVolta.diff(event, "days");
            this.busca.nrnoites = diffdays;
        }
    }

    // onChangeVolta(event, trechos, indice) {
    //   console.log(this.busca);
    //
    //   this.GetDateDiffVolta(event, trechos, indice);
    // }

    GetDateDiffVolta(event, trechos, indice) {
        if (trechos[indice].dataVolta != null) {
            let diffdays = event.diff(trechos[indice].dataIda, "days");
            this.busca.nrnoites = diffdays;
        }
    }


    minDate: any = moment().format('YYYY-MM-DD');
    buscaLojas(val: string) {
        this.carroService.buscarPorParametro(val, 1).subscribe(
            dados => {
                this.lojas = dados.Items.map(x => {
                    let tipo = "";
                    if (x.Tipo == "A") {
                        tipo = "Aeroporto";
                    } else if (x.Tipo == "C") {
                        tipo = "Cidade";
                    }

                    x.display_field = `${x.Sigla} - ${x.Nome} / ${tipo}`;
                    return x;
                })
            },
            erro => {
                console.log(erro);
                this.lojas = [];
            }
        )
    }

    selecionaLojaOrigem(item) {
        this.busca.localOrigem = item;
        this.busca.localDevolucao = JSON.parse(JSON.stringify(this.busca.localOrigem));
    }

    selecionaLojaDevolucao(item) {
        this.busca.localDevolucao = item;
    }

    buscaLojasDebounce(val: string) {
        clearTimeout(this.timerCidade);
        this.timerCidade = setTimeout(() => {
            this.buscaLojas(val);
        }, 600);
    }

    setDataOrigem($event) {
        this.busca.dataDevolucao = moment(this.busca.dataOrigem).add(1, 'days').format('YYYY-MM-DD');
    }

    setDataDevolucao($event) {
        if (moment(this.busca.dataOrigem) > moment(this.busca.dataDevolucao)) {
            this.busca.dataOrigem = moment(this.busca.dataOrigem).subtract(1, 'days').format('YYYY-MM-DD');
        }
    }

}
